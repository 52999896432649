import { AError } from "../../classes/AError.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { AConvertMillisecondsToHM, AConvertToGridColumns, AConvertToGridData, AFormatDate, AShowTable } from "../../utils/tools.js";
export class APage {
    constructor() {
        FilterManager.load();
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
    }
    async init() {
        this.translations = await Loading.waitForPromises(Translate.get([
            'Status',
            'Online',
            'Offline',
            'Session Timeline',
            'Duration',
            'To',
            'From'
        ]));
        const $print = $('#print');
        const $export = $('#export');
        Events.on(`ACI_TABS_CHANGED->view`, ({ tabview, $tabview }) => {
            // $flex_content.css({ 'background-color': (tabview === 'tab-table') ? 'inherit' : '#fff' })
            $print.toggleClass('hidden', (tabview === 'tab-table'));
            $export.toggleClass('hidden', !(tabview === 'tab-table'));
        });
        this.createPrintHandler();
    }
    flipDates(datePairs) {
        const loggedOut = [];
        let last = datePairs[0];
        for (let i = 1; i < datePairs.length; i++) {
            const [left, right] = datePairs[i];
            loggedOut.push(
            // @ts-ignore
            [last[1], left]);
            last = datePairs[i];
        }
        return loggedOut;
    }
    fixColors() {
        const colors = [
            // 'red',
            'green'
        ];
        $('.highcharts-color-undefined > rect').toArray().map((ele, i) => {
            return $(ele).attr('fill', colors[i]);
        });
    }
    fetchTimeline() {
        // TODO: Implement new timeline query
        return Loading.waitForPromises(requestService.query({
            Query: (`
        SELECT
          sg0.sessionid,
          sg0.devicename,
          sg0.StartTime,
          sg2.StopTime
          ${'' /** // TODO: Fix sessions > 24 hours SEC_TO_TIME(TIMESTAMPDIFF(SECOND, sg0.StartTime, IFNULL(sg2.StopTime, NOW()))) as Duration **/}
        FROM (
          SELECT s0.sessionid, s0.devicename, Max(s0.time) StartTime
          FROM sessionstatus s0
          INNER JOIN (
            SELECT distinct sessionid
            FROM sessionstatus
            WHERE (\`time\` BETWEEN Date_sub(:FromDate, INTERVAL 1 Day) AND :ToDate) AND devicename LIKE 'scan%'
          ) s1 using(sessionid)
          WHERE s0.comstate = 'Sessionstarted' AND s0.status = 'readytoenforce'
          GROUP BY s0.sessionid, s0.devicename
        ) sg0
        left join (
          SELECT s2.sessionid, s2.devicename, Min(s2.time) StopTime
          FROM sessionstatus s2
          INNER JOIN (
            SELECT distinct sessionid
            FROM sessionstatus
            WHERE (\`time\` BETWEEN :FromDate AND date_add(:ToDate, INTERVAL 1 Day)) AND devicename LIKE 'scan%'
          ) s3 using(sessionid)
          WHERE (s2.comstate = 'Sessionstopped' OR s2.STATUS = 'Disconnected')
          GROUP BY s2.sessionid, s2.devicename
        ) sg2 USING (sessionid) 
        WHERE StartTime >= :FromDate OR StopTime IS NOT NULL
        ORDER BY sg0.devicename, sg0.StartTime
      `),
            Params: FilterManager.saveExplicit()
        }));
    }
    async chartToTableData(data) {
        if (data.length === 0) {
            return {
                Columns: [],
                ColumnsTranslated: [],
                Rows: []
            };
        }
        const tableData = [];
        data.map(item => {
            const { x, x2, y, duration, device } = item;
            tableData.push({
                DeviceName: device,
                SessionStart: AFormatDate(new Date(x)),
                SessionStop: AFormatDate(new Date(x2)),
                Duration: duration
            });
        });
        const tableTranslations = await Translate.get(Object.keys(tableData[0]));
        const tableRes = {
            Columns: Object.keys(tableData[0]),
            ColumnsTranslated: Object.keys(tableData[0]).map(key => tableTranslations[key]),
            Rows: tableData.map(({ DeviceName, SessionStart, SessionStop, Duration }) => [DeviceName, SessionStart, SessionStop, Duration])
        };
        return tableRes;
    }
    transform1(res) {
        let i = 0;
        const devices = {};
        res.Rows.map((row) => {
            const [_, DeviceName, StartTime, StopTime] = row;
            if (!devices.hasOwnProperty(DeviceName)) {
                devices[DeviceName] = {
                    y: i++,
                    array: []
                };
            }
            devices[DeviceName].array.push([
                new Date(StartTime),
                new Date(StopTime)
            ]);
            devices[DeviceName].opposite = this.flipDates(devices[DeviceName].array);
        });
        return devices;
    }
    transform2(devices) {
        const output = [];
        Object.keys(devices).map((DeviceName) => {
            const { y, array, opposite } = devices[DeviceName];
            for (const item of array) {
                const [StartDate, StopDateUnparsed] = item;
                const StopDate = StopDateUnparsed.getTime() !== 0 ? StopDateUnparsed : new Date();
                output.push({
                    x: StartDate.getTime(),
                    x2: StopDate.getTime(),
                    y: y,
                    duration: AConvertMillisecondsToHM(StopDate.getTime() - StartDate.getTime()),
                    device: DeviceName,
                    // @ts-ignore
                    day: moment(StartDate).format('YYYY-MM-DD'),
                    status: this.translations['Online'],
                    partialFill: 0.99
                });
            }
        });
        return output;
    }
    async refresh() {
        const filters = FilterManager.save();
        FilterManager.setActive(false);
        try {
            const res = await Loading.waitForPromises(this.fetchTimeline());
            const hasData = (res.Rows.length > 0);
            if (!hasData) {
                return Alerts.noResults();
            }
            const { translations } = this;
            const devices = this.transform1(res);
            const data = this.transform2(devices);
            this.chart = themeService.watchChart(Highcharts.chart({
                chart: {
                    renderTo: 'timeline',
                    type: 'xrange',
                    zooming: {
                        type: 'x',
                    },
                    events: {
                        redraw: () => { this.fixColors(); }
                    }
                },
                title: {
                    text: this.translations['Session Timeline']
                },
                xAxis: {
                    type: 'datetime',
                    min: new Date(filters.FromDate).getTime(),
                    max: new Date(filters.ToDate).getTime()
                },
                yAxis: {
                    title: { text: '' },
                    categories: Object.keys(devices),
                    reversed: true
                },
                tooltip: {
                    formatter: function () {
                        const { point } = this;
                        // @ts-ignore
                        const { x, x2, y, status, duration } = point;
                        const from = AFormatDate(new Date(x));
                        const to = AFormatDate(new Date(x2));
                        return (`
              <b>${translations['Status']}</b>: ${status}<br>
              <b>${translations['Duration']}</b>: ${duration}<br>
              <b>${translations['From']}</b> ${from}<br>
              <b>${translations['To']}</b> ${to}
            `);
                    }
                },
                series: [
                    {
                        type: 'xrange',
                        name: translations['Online'],
                        // color: '#00ff00',
                        pointWidth: 20,
                        data: data.map((item) => {
                            return Object.assign({}, item, {
                                color: 'green'
                            });
                        }),
                        dataLabels: {
                            enabled: false
                        },
                        turboThreshold: 0,
                        color: 'green'
                    }
                ],
                legend: {},
                exporting: {
                    allowHTML: true
                }
            }));
            const tableData = await this.chartToTableData(data);
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                columns: AConvertToGridColumns(tableData, {
                    'DeviceName': { hidden: true }
                }),
                data: AConvertToGridData(tableData)
            });
            if (hasData) {
                this.grid.store.group('DeviceName', false);
                PageScript.grid.collapseAll();
            }
            this.togglePrint(hasData);
            this.fixColors();
        }
        catch (err) {
            AError.handle(err);
        }
        finally {
            FilterManager.setActive(true);
            $('[aci-show-on-input]').removeClass('hidden');
        }
    }
    createPrintHandler() {
        const $print = $('#print');
        $print.on('click', _ => this.chart.print());
    }
    togglePrint(enablePrint) {
        const $print = $('#print');
        if (enablePrint) {
            $print.removeAttr('disabled');
        }
        else {
            $print.attr('disabled', 'disabled');
        }
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>
        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>

    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div class="flex-content">
        <div class="aci-tabs tabs-sticky tabs-fixed-md top-zero tabs-fw" tabgroup="view">
          <button class="aci-tab active" tab="tab-chart">
            <span>Chart</span>
          </button>
          <button class="aci-tab" tab="tab-table">
            <span>Table</span>
          </button>
        </div>
        <div class="columns col-gapless" style="height: calc(100% - 60px);">
          <div class="column col-12">
            <div tabgroup="view" tabview="tab-chart" class="h-padding fh">
              <div template="${ATemplates.WaitingForInput}"></div>
              <div aci-show-on-input="true" id="timeline" class="hidden"></div>
            </div>
            <div tabgroup="view" tabview="tab-table" class="h-padding fh">
              <div template="${ATemplates.WaitingForInput}"></div>
              <div aci-show-on-input="true" id="table-bryntum" class="fh hidden"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="columns footer aci">
        <div class="column col-2 col-ml-auto">
          <button id="print" class="btn btn-primary col-12" disabled="disabled">Print</button>
          <button id="export" class="btn btn-primary col-12 hidden" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
