import { ADeskControl } from "../../classes/ADeskControl.js";
import { AError } from "../../classes/AError.js";
import { AExportMap } from "../../classes/AExportMap.js";
import { COLUMN_DATETIME, COLUMN_HIDDEN, COLUMN_TEXT, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { AImageHelper } from "../../classes/AImageHelper.js";
import { genLegend } from "../../classes/ALegend.js";
import { APhotoFetcher } from "../../classes/APhotoFetcher.js";
import { ADetectionState, AIllegallyParked, AParkingRight, AVerification } from "../../classes/AUnificationTypes.js";
import { AEngine, sleep } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { MAP_OPTIONS } from "../../core/maps/AMapStructs.js";
import { ADragService } from "../../services/ADragService.js";
import { EVENTS } from "../../services/AEventService.js";
import { APreferenceService, APrefs } from "../../services/APreferenceService.js";
import { ARouteDrawingService } from "../../services/ARouteDrawingService.js";
import { createMap, createPano, RequestMapRouteFull, RequestMapScans, ShowMapScans } from "../../utils/maps.js";
import { DetectionsFullRanged } from "../../utils/query.js";
import { AConvertToGridColumns, AConvertToGridData, AShowTable, DeviceMultiOptions, generateTreeDropdown } from "../../utils/tools.js";
const preferenceService = AEngine.get(APreferenceService), routeDrawingService = AEngine.get(ARouteDrawingService), dragService = AEngine.get(ADragService);
export class APage {
    get category() { return $('#Category').val(); }
    constructor() {
        this.markers = [];
        this.RouteList = [];
        this.SessionMarkers = {};
        this.map = createMap('map', {
            streetViewControl: true,
            streetView: createPano('streetview')
        });
        this.imageHelper = new AImageHelper({ $rh: $('.photos'), $photos: $('.photos') });
        $('#toggle-images').on('click', _ => this.toggleImageView());
        $('#toggle-layout').on('click', _ => this.toggleLayout());
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
    }
    async init() {
        FilterManager.load();
        if (preferenceService.load(APrefs.HIDE_IMAGE_VIEW, false)) {
            this.toggleImageView();
        }
        sleep(10).then(() => {
            this.toggleLayout(preferenceService.load(APrefs.LAYOUT_ADVANCED, false));
        });
        await Loading.waitForPromises([
            generateTreeDropdown('#ParkingRight', new AParkingRight()),
            generateTreeDropdown('#Verification', new AVerification()),
            generateTreeDropdown('#IllegallyParked', new AIllegallyParked()),
            generateTreeDropdown('#DetectionState', new ADetectionState()),
            generateTreeDropdown('#DeviceMulti', DeviceMultiOptions(), {
                useIndices: false,
                attributeToUse: 'unificationindex',
            }),
        ]);
        nodeSessionService.bindSessionsToMap({
            interpolate: false,
            mapMarkers: this.SessionMarkers,
            map: this.map
        });
        // this.showEmptyTable()
        await mapHelperService.prepareMapItems(MAP_OPTIONS.All, {
            showLegend: true,
            showSearch: true,
            createLabel: true
        });
        await this.setupViewSections();
        Events.tryInvoke(EVENTS.CONTENT_RESIZE);
    }
    async setupViewSections() {
        const eventKeys = dragService.createDraggableGeneric();
        for (const resizeEventKey of eventKeys) {
            Events.on(resizeEventKey, () => {
                const targets = [this.map, this.map.getStreetView()].filter(v => v != null);
                targets.map(t => google.maps.event.trigger(t, 'resize'));
            });
        }
        Events.on(EVENTS.CONTENT_RESIZE, _ => {
            $('.imageContainer .image').each((i, ele) => {
                $(ele).data('zoom').updateOffset();
            });
        });
        const ScrollBarHeight = 10;
        this.DeskControl = new ADeskControl({
            imageHelper: this.imageHelper
        });
        this.DeskControl.setPreferredImageHeight(200 - ScrollBarHeight);
        this.PhotoFetcher = new APhotoFetcher();
    }
    toggleLayout(useAdvancedLayout) {
        const { active, layout } = dragService.toggleLayout('.splitter', 'three', useAdvancedLayout);
        this.map.setPanorama(layout ? createPano('streetview') : null);
        google.maps.event.trigger(this.map, 'layout_changed', this.map);
        $('#toggle-layout').children().map((i, s) => $(s).toggleClass('hidden', (active ? i === 1 : i === 0)));
        Events.tryInvoke(EVENTS.CONTENT_RESIZE);
        preferenceService.save(APrefs.LAYOUT_ADVANCED, active);
    }
    toggleImageView() {
        const $toggle = $('#toggle-images');
        const $splitter = $('.splitter');
        // $toggle.closest('.img-tab-toggle').toggleClass('toggled')
        $toggle.children().eq(0).toggleClass('hidden');
        $toggle.children().eq(1).toggleClass('hidden');
        $splitter.toggleClass('v-expand');
        $('.flex-content').toggleClass('no-overflow');
        preferenceService.save(APrefs.HIDE_IMAGE_VIEW, $('.splitter').hasClass('v-expand'));
    }
    async refresh() {
        FilterManager.setActive(false);
        const filters = FilterManager.saveExplicit();
        this.DeskControl.clearDetections({ redraw: true });
        return Loading.waitForPromises(RequestMapScans(filters, DetectionsFullRanged)).then(async (detectionRes) => {
            const { First, Last } = detectionRes;
            const routeRes = await Loading.waitForPromises(RequestMapRouteFull(filters, detectionRes.Size > 1 ? {
                clampBetween: { min: new Date(First.DetectionTime), max: new Date(Last.DetectionTime) }
            } : undefined));
            this.imageHelper.clearImageSet();
            mapHelperService.destroy(this.markers);
            mapHelperService.destroy(this.RouteList);
            FilterManager.setActive(true);
            if (detectionRes.isEmpty && routeRes.isEmpty) {
                return Alerts.noResults();
            }
            this.legend?.destroy();
            this.bounds = new google.maps.LatLngBounds();
            this.markers = ShowMapScans({
                response: detectionRes,
                map: this.map,
                bounds: this.bounds,
                overrideClickHandler: true
            });
            if (this.grid && this.grid.store.records.length > 0) {
                const scrollTop = this.grid.storeScroll();
                this.grid.store.data = AConvertToGridData(detectionRes, {
                    'DetectionTime': DATA_DATETIME
                });
                this.grid.restoreScroll(scrollTop);
            }
            else {
                this.grid = AShowTable({
                    appendTo: 'table-bryntum',
                    columns: AConvertToGridColumns(detectionRes, {
                        '*': COLUMN_HIDDEN,
                        'DetectionTime': COLUMN_DATETIME,
                        'LicensePlate': COLUMN_TEXT,
                        'Area': COLUMN_TEXT,
                        'Side': COLUMN_TEXT,
                        'VehicleType': COLUMN_TEXT,
                        // 'IsIllegallyParked': COLUMN_BOOLEAN,
                        // 'HasParkingRight': COLUMN_BOOLEAN,
                    }),
                    data: AConvertToGridData(detectionRes, {
                        'DetectionTime': DATA_DATETIME
                    })
                });
            }
            this.combineTableWithMap();
            // =================== EXPORT  MAP =================== //
            const exportMap = new AExportMap('scans advanced', { scales: true, markersArePolygons: true });
            exportMap.prepareCache();
            if (filters.ShowRoute === true) {
                this.legend = await genLegend({
                    map: this.map,
                    smallTitle: true,
                    profileKey: this.category,
                });
                this.RouteList = await routeDrawingService.showMapRoute(this.map, routeRes, {
                    category: this.category,
                    legend: this.legend,
                    bounds: this.bounds,
                });
                exportMap.addLines(this.RouteList);
            }
            exportMap.allowExport();
            // =================================================== //
            this.map.fit(this.bounds);
        }).catch(AError.handle);
    }
    combineTableWithMap() {
        // Create map click event handler
        for (let m of this.markers) {
            this.DeskControl.addDetection(m);
            m.addListener("click", (e) => {
                if (e.src !== 'grid') {
                    const foundRecord = this.grid.store.find(record => {
                        const { DetectionId, DetectionDeviceId } = record.data;
                        return m.get('DetectionDeviceId') === DetectionDeviceId && m.get('DetectionId') === DetectionId;
                    });
                    this.grid.selectedRecord = foundRecord;
                }
            });
        }
        this.grid.on('selectionchange', async (e) => {
            try {
                const record = this.grid.selectedRecord?.data;
                if (e.mode !== 'row' || !record)
                    return;
                if ((e.source?.event?.button ?? 0) !== 0) {
                    this.grid.deselectAll();
                    await purgatoryService.closeInfoWindow();
                    return;
                }
                const id = detectionService.toUniqueId(record); // const identifier = (`${record.DetectionDeviceId}_${record.DetectionId}`)
                // const marker = this.DeskControl.cachedIdentifiers[id]
                const marker = this.markers.find((m) => m.get('Id') === id);
                new google.maps.event.trigger(marker, 'click', { src: 'grid' });
                const [_, imageCount] = await Loading.waitForPromises([
                    purgatoryService.onMarkerClickRealtime(marker),
                    this.showImages(marker)
                ]);
                $('#toggle-images span:last-child').text(`${imageCount}`);
            }
            catch (err) {
                AError.handle(err);
            }
        });
    }
    async showImages(marker) {
        // Clear Images
        this.imageHelper.clearImageSet();
        // Fetch detection photo's
        const fetchedImages = (marker.images && marker.images.length) ?
            marker.images.map(img => { return { src: img }; }) :
            await new APhotoFetcher().fetchPhotos('all', { marker });
        // Add image set to presentation layer
        await this.imageHelper.addImageSet(fetchedImages.map(obj => obj.src), {
            allowFilter: true,
            allowFullscreen: true,
            allowZoom: false,
            defaultSize: {
                height: 200 - (Number(this.imageHelper.$photos.attr('scrollbar-height')) + 2 || 0),
                width: null
            }
        });
        return fetchedImages.length;
    }
}
export function css() {
    return ( /*html*/`
    <style>
      #AjaxContent .wrapper.md {
        height: calc(50% - 79px);
      }

      .splitter .aci-map {
        height: 100%;
      }

      .splitter #streetview {
        height: 100%;
      }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="columns filter-bar side-filter-bar">
      <div class="column col-12 c-scroll">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ParkingRight">ParkingRight</label>
          <div id="ParkingRight" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="Verification">Verification</label>
          <div id="Verification" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="IllegallyParked">IllegallyParked</label>
          <div id="IllegallyParked" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="DetectionState">DetectionState</label>
          <div id="DetectionState" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>
        
        <div class="form-group">
          <label class="form-label" for="DeviceMulti">Device</label>
          <div id="DeviceMulti" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="Area">Area</label>
          <select class="form-select" id="Area">
            <option value="%" SELECTED>All</option>
            <option value="Unknown">Unknown</option>
          </select>
        </div>
        
        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>

        <div class="form-group">
          <label class="form-switch">
            <input id="ShowRoute" type="checkbox">
            <i class="form-icon"></i> Show Route
          </label>
        </div>

        <div class="form-group">
          <label class="form-label" for="Category">Category</label>
          <select enabled-if="ShowRoute" class="form-select" id="Category">
            <option value="speed">Speed</option>
            <option value="precision">Precision</option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="RouteLimit">Max Route Points</label>
          <input enabled-if="ShowRoute" class="form-input" type="number" id="RouteLimit" value="5000">
        </div>

        <div class="form-group hidden">
          <div class="children-inline-flex toggle toggle-advanced-filters">
            <label class="form-label">Advanced Filters</label>
            <i class="fa-solid fa-chevron-up fa-xs"></i>
          </div>
        </div>
        <div class="advanced-filters col-12 hidden"> </div>
        
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div class="flex-child bryntum-container has-footer-2">
      <div class="flex-content">
        <div class="splitter" style="overflow: hidden; height: calc(100% - 200px);">
          <div class="drag-section part-one">
            <div template="${ATemplates.WaitingForInput}"></div>
            <div id="table-bryntum" class="fh hidden"></div>
          </div>

          <div id="separator1" class="drag-seperator separator"></div>
          <div class="drag-section part-two">
            <div id="map" class="aci-map"></div>
          </div>
          <div id="separator2" class="drag-seperator separator"></div>

          <div class="drag-section part-three" style="overflow-y: auto;">
            <div id="streetview">
              <div id="markerDiv"></div>
            </div>
          </div>

        </div>
        <div class="photos photos-scroll-container photos-size-200 c-scroll">
          <div show-if-no-results="true" class="center-no-results">
            <div class="fw text-center mb-2">
              <i class="fa-light fa-camera-viewfinder fa-4x"></i>
            </div>
            <div class="no-images-warning">
              Could not find any photos!
            </div>
          </div>
        </div>
      </div>
      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Scans</div>
        </div>
        <div class="column col-auto col-ml-auto">
          <button id="toggle-images" class="btn btn-grey col-12">
            <span><i class="fa-solid fa-angle-down"></i> Hide Images</span>
            <span class="hidden"><i class="fa-solid fa-angle-up"></i> Show Images</span>
            <span>0</span>
          </button>
        </div>
        <div class="column col-auto">
          <button id="toggle-layout" class="btn btn-grey col-12">
            <span><i class="fa-regular fa-table-columns fa-fw"></i> Change Layout</span>
            <span class="hidden"><i class="fa-regular fa-columns-3 fa-fw"></i> Change Layout</span>
          </button>
        </div>
      </div>
    </div>
  `);
}
