import { AConfig } from "../../classes/AConfig.js";
import { AError } from "../../classes/AError.js";
import { AExportMap } from "../../classes/AExportMap.js";
import { genLegend } from "../../classes/ALegend.js";
import { ADetectionState, AIllegallyParked, AParkingRight, AVerification } from "../../classes/AUnificationTypes.js";
import { AEngine } from "../../core/AEngine.js";
import { MAP_OPTIONS } from "../../core/maps/AMapStructs.js";
import { ARouteDrawingService } from "../../services/ARouteDrawingService.js";
import { createMap, DefaultBounds, RequestMapRouteFull, RequestMapScans, ShowMapScans } from "../../utils/maps.js";
import { DetectionsFullRanged } from "../../utils/query.js";
import { DeviceMultiOptions, generateTreeDropdown } from "../../utils/tools.js";
var routeDrawingService = AEngine.get(ARouteDrawingService);
export class APage {
    get category() { return $('#Category').val(); }
    constructor() {
        this.RouteList = [];
        this.markers = [];
        this.SessionMarkers = {};
        this.map = createMap('map', {
            streetViewControl: true
        });
        this.bounds = DefaultBounds();
    }
    async init() {
        FilterManager.load();
        await Loading.waitForPromises([
            generateTreeDropdown('#ParkingRight', new AParkingRight()),
            generateTreeDropdown('#Verification', new AVerification()),
            generateTreeDropdown('#IllegallyParked', new AIllegallyParked()),
            generateTreeDropdown('#DetectionState', new ADetectionState()),
            generateTreeDropdown('#DeviceMulti', DeviceMultiOptions(), { useIndices: false, attributeToUse: 'unificationindex' }),
        ]);
        await mapHelperService.prepareMapItems(MAP_OPTIONS.All, {
            showLegend: true,
            showSearch: true
        });
        this.map.fit();
        nodeSessionService.bindSessionsToMap({
            interpolate: false,
            mapMarkers: this.SessionMarkers,
            map: this.map
        });
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
    }
    createLegend() {
        return genLegend({
            map: this.map,
            smallTitle: true,
            profileKey: this.category,
        });
    }
    async refresh() {
        const filters = FilterManager.saveExplicit();
        FilterManager.setActive(false);
        // TODO: Remove log
        if (AEngine.isDevelopmentMode) {
            if (filters.VerifyResult) {
                AEngine.log(`WHERE (VerifyResult ${FilterManager.buildQueryFindInArray(filters.VerifyResult)})`);
            }
            if (filters.ParkingRightType) {
                AEngine.log(`WHERE (ParkingRightType ${FilterManager.buildQueryFindInArray(filters.ParkingRightType)})`);
            }
        }
        mapHelperService.destroy(this.markers);
        mapHelperService.destroy(this.RouteList);
        return Loading.waitForPromises(RequestMapScans(filters, DetectionsFullRanged)).then(async (ares) => {
            const { First, Last } = ares;
            const routeRes = await Loading.waitForPromises(RequestMapRouteFull(filters, ares.Size > 1 ? {
                clampBetween: { min: new Date(First.DetectionTime), max: new Date(Last.DetectionTime) }
            } : undefined));
            FilterManager.setActive(true);
            if (ares.isEmpty && routeRes.isEmpty) {
                return Alerts.noResults();
            }
            this.legend?.destroy();
            this.markers = ShowMapScans({
                response: ares,
                map: this.map, // Map reference
            });
            const exportMap = new AExportMap('scans', { scales: true });
            // Stores last cached query from ARequest.js
            exportMap.prepareCache();
            if (filters.ShowRoute === true) {
                this.legend = await this.createLegend();
                this.RouteList = await routeDrawingService.showMapRoute(this.map, routeRes, {
                    category: this.category,
                    legend: this.legend,
                    bounds: this.bounds,
                });
                exportMap.addLines(this.RouteList);
            }
            // Rebinds click method for export kml button
            // Adds markers from cache
            // Creates click handler to execute url creation
            // Displays download popup window
            // Adds Areas, Zones, ParkingSpaces, Segments if enabled
            exportMap.allowExport();
            this.map.focusOnMarkers();
        }).catch(AError.handle);
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>
    
        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>
    
        <div class="form-group">
          <label class="form-label" for="ParkingRight">ParkingRight</label>
          <div id="ParkingRight" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>
    
        <div class="form-group">
          <label class="form-label" for="Verification">Verification</label>
          <div id="Verification" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>
    
        <div class="form-group">
          <label class="form-label" for="IllegallyParked">IllegallyParked</label>
          <div id="IllegallyParked" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>
    
        <div class="form-group">
          <label class="form-label" for="DetectionState">DetectionState</label>
          <div id="DetectionState" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>

        ${
    /*configService.get('filters.enableParkingRightTypeFilter') ? (`*/
    AConfig.get('filters.enableFilterParkingRightType', false) ? ( /*html*/`
            <div class="form-group">
              <label class="form-label" for="ParkingRightType">ParkingRightType</label>
              <div id="ParkingRightType" class="dd-disallow-none" maxlength="64">
                <span>None</span>
                <ul class="dropdown c-scroll"></ul>
              </div>
            </div>
          `) : ''}
    
        ${
    /*configService.get('filters.enableVerifyResultFilter') ? (`*/
    AConfig.get('filters.enableFilterVerifyResult', false) ? ( /*html*/`
            <div class="form-group">
              <label class="form-label" for="VerifyResult">VerifyResult</label>
              <div id="VerifyResult" class="dd-disallow-none" maxlength="64">
                <span>None</span>
                <ul class="dropdown c-scroll"></ul>
              </div>
            </div>
          `) : ''}
        
        <div class="form-group">
          <label class="form-label" for="DeviceMulti">Device</label>
          <div id="DeviceMulti" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>
    
        <div class="form-group">
          <label class="form-label" for="Area">Area</label>
          <select class="form-select" id="Area">
            <option value="%" SELECTED>All</option>
            <option value="Unknown">Unknown</option>
          </select>
        </div>
    
        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>
    
        <div class="form-group">
          <label class="form-switch">
            <input id="ShowRoute" type="checkbox">
            <i class="form-icon"></i> Show Route
          </label>
        </div>

        <div class="form-group">
          <label class="form-label" for="Category">Category</label>
          <select enabled-if="ShowRoute" class="form-select" id="Category">
            <option value="speed">Speed</option>
            <option value="precision">Precision</option>
          </select>
        </div>
    
        <div class="form-group">
          <label class="form-label" for="RouteLimit">Max Route Points</label>
          <input enabled-if="ShowRoute" class="form-input" type="number" id="RouteLimit" value="5000">
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div class="flex-child">
      <div id="map" class="aci-map"></div>
    </div>
  `);
}
