import { AError } from "../../classes/AError.js";
import { COLUMN_BOOLEAN, COLUMN_DATETIME, COLUMN_HIDDEN, COLUMN_NUMBER, COLUMN_PRICE_NO_CURR, COLUMN_TIME, DATA_DATETIME, COLUMN_ACTION } from "../../classes/AGridTypes.js";
import { APhotoHandler } from "../../classes/APhotoHandler.js";
import { ADetectionState, AIllegallyParked, AParkingRight, AVerification } from "../../classes/AUnificationTypes.js";
import { AEngine } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { ATableFormatter } from "../../core/form/table/ATableFormatter.js";
import { ALERT_BUTTONS, ALERT_TITLES } from "../../services/AAlertService.js";
import { ADetectionService } from "../../services/ADetectionService.js";
import { generateWhereClause } from "../../utils/maps.js";
import { DetectionsFullRanged, FinesSelectFields, FinesSelectFieldsArr, UnificationTypes } from "../../utils/query.js";
import { initTableAccordions } from "../../utils/table_accordions.js";
import { AConvertToGridColumns, AConvertToGridData, appendResponseRows, AShowTable, AUrlEncodedImageFromBase64, generateTreeDropdown } from "../../utils/tools.js";
// TODO: maybe FineData and FineImage in popop if later needed
// let FinesSelectField = `DetectionDevice AS 'Device', DetectionTime, DetectionUser AS 'D-User', IFNULL(u.ExternalId, '') as ExternalId, LicensePlate, CountryCode AS 'Country', OffenceCode, OffenceText, OffencePrice, ParkingRight AS 'Right', IllegallyParked, VerificationDevice as 'ResultDevice', VerificationEndTime as 'ResultTime', VerificationUser AS 'ResultUser', VerificationChannel AS 'Channel',Verification AS 'Result', DetectionState as State, FineNumber,  ParkingSpace, Zone, Area, RouteArea, Address, DetectionDeviceId, DetectionId`
export class APage {
    constructor() {
        FilterManager.load();
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh().catch(AError.handle)));
    }
    async init() {
        await Loading.waitForPromises([
            generateTreeDropdown('#ParkingRight', new AParkingRight()),
            generateTreeDropdown('#IllegallyParked', new AIllegallyParked()),
            generateTreeDropdown('#DetectionState', new ADetectionState()),
        ]);
    }
    async refresh() {
        const filters = FilterManager.saveExplicit();
        // const { FromDate, ToDate, LicensePlate, FineNumber, Limit } = FilterManager.save()
        FilterManager.setActive(false);
        const v = new AVerification().Options.Fined;
        const whereClause = generateWhereClause({ filters });
        // console.log({ ReportsSelectFields })
        // TODO: Find a way to get externalid without comparing DisplayName
        AEngine.log('FinesSelectFields', FinesSelectFields);
        const response = await Loading.waitForPromises(requestService.query({
            Name: `Fines XPTR`,
            Query: (`
          SELECT ${FinesSelectFields} FROM (${DetectionsFullRanged}) full
          LEFT JOIN user_session_start s USING (SessionId)
          LEFT JOIN users u ON (s.User = u.User)
          WHERE ${whereClause} AND Verification BETWEEN :FineStart AND :FineEnd
          GROUP BY DetectionId, DetectionDeviceId, SessionId, u.ExternalId
          LIMIT :Limit
        `),
            Params: {
                ...filters,
                FineStart: v.FirstIndex,
                FineEnd: v.LastIndex,
            },
            Language: Language,
            Translate: ["Device", "Right", "ResultDevice", "Result"].concat(UnificationTypes)
        }));
        FilterManager.setActive(true);
        if (!response.Rows.length) {
            Alerts.noResults();
            return;
        }
        appendResponseRows(response, ['Action1', 'Action2']);
        this.grid = AShowTable({
            appendTo: 'table-bryntum',
            columns: AConvertToGridColumns(response, {
                'DetectionTime': COLUMN_DATETIME,
                'Time': COLUMN_TIME,
                'OffenceCode': COLUMN_HIDDEN,
                'OffenceText': {},
                'OffenceDescription': {},
                'OffencePrice': COLUMN_PRICE_NO_CURR,
                // 'VerificationChannel': {},
                // 'SessionVerificationChannel': {},
                // 'SessionEnforceUnit': {},
                'IsIllegallyParked': { ...COLUMN_BOOLEAN, ...COLUMN_HIDDEN },
                'HasParkingRight': { ...COLUMN_BOOLEAN, ...COLUMN_HIDDEN },
                'DetectionValid': COLUMN_BOOLEAN,
                'ScanDeviceLatitude': COLUMN_HIDDEN,
                'ScanDeviceLongitude': COLUMN_HIDDEN,
                'VehicleCenterLatitude': COLUMN_HIDDEN,
                'VehicleCenterLongitude': COLUMN_HIDDEN,
                'FinalVerificationDistanceToVehicle': { ...COLUMN_NUMBER, ...COLUMN_HIDDEN },
                'ResultTime': COLUMN_DATETIME,
                'DetectionId': COLUMN_HIDDEN,
                'DetectionDeviceId': COLUMN_HIDDEN,
                'IllegallyParked': {
                    htmlEncode: false,
                    renderer: ({ value, record, cellElement }) => {
                        if (record.keyIllegallyParked === illegallyParkedRef.Options.NotIllegallyParked.Key) {
                            cellElement?.classList?.add('text-grey-i');
                        }
                        return value;
                    },
                },
                'ParkingRight': {
                // htmlEncode: false,
                // renderer: ({value, record, cellElement}) => {
                //   if (record.keyParkingRight === parkingRightRef.Options.NoParkingRight.Key) {
                //     cellElement?.classList?.add('text-red-i')
                //   }
                //   return value
                // },
                },
                'Right': {
                // htmlEncode: false,
                // renderer: ({value, record, cellElement}) => {
                //   if (record.keyParkingRight === parkingRightRef.Options.NoParkingRight.Key) {
                //     cellElement?.classList?.add('text-red-i')
                //   }
                //   return value
                // },
                },
                'Result': {
                    htmlEncode: false,
                    renderer: ({ value, record, cellElement }) => {
                        if (record.keyVerification === verificationRef.Options.NoVerificationNeeded.Key) {
                            cellElement?.classList?.add('text-grey-i');
                        }
                        return value;
                    },
                },
                'HasFineData': {
                    ...COLUMN_ACTION({
                        iconCls: 'fa-regular fa-money-check-pen',
                        btnCls: 'btn-white',
                        // renderCls(record) {
                        //   return [
                        //     ...((record.data.HasFineData) ? ['text-red-i'] : []),
                        //     ...((record.data.HasFineImage) ? ['text-white-i'] : []),
                        //   ].join(' ')
                        // },
                        disablePredicate(record) {
                            return !record.data.HasFineData;
                        },
                        onClick({ record }) {
                            if (!record.data.HasFineData) {
                                return;
                            }
                            AEngine.log('process click!');
                            Loading.waitForPromises(requestService.fetch({
                                AssertValues: true,
                                Query: ( /*SQL*/`
                    SELECT
                      FineNumber,
                      FineIsPaid,
                      FinePaidReference,
                      IF(CAST(FineData AS CHAR) != '{}', FineData, NULL) AS FineData,
                      VerificationVersion,
                      FineImage
                    FROM verifications
                    WHERE
                      DetectionId=:DetectionId AND
                      DetectionDeviceId=:DetectionDeviceId
                    ORDER BY VerificationVersion DESC
                  `),
                                Params: {
                                    DetectionId: record.data.DetectionId,
                                    DetectionDeviceId: record.data.DetectionDeviceId,
                                }
                            })).then(async (ares) => {
                                AEngine.log('ares', ares);
                                // const html = await this.buildTable({ data: Object.assign({}, this.baseMetaData, this.metadata) })
                                const aggregatedData = {};
                                // Itterates rows to that all the not null information gets combined
                                ares.map(it => {
                                    Object.keys(it).map(key => {
                                        if (!aggregatedData.hasOwnProperty(key) || aggregatedData[key] === null) {
                                            aggregatedData[key] = it[key];
                                        }
                                    });
                                });
                                AEngine.log('FINE [%cCOMBINED DATA%n]:', aggregatedData);
                                const tb = await purgatoryService.createSingleTable({
                                    tableId: idAllocatorService.getNextId({ prefix: 'dyn-table-' }),
                                    data: aggregatedData,
                                    tableFormatter: new ATableFormatter({
                                        "*": { type: 'DYNAMIC', expanded: true },
                                        "FineIsPaid": { type: 'BOOLEAN' },
                                        "VerificationVersion": { type: 'HIDDEN' },
                                        "FineImage": {
                                            type: 'BLOB',
                                            onClick: async ($ele, data) => {
                                                APhotoHandler.showImageFullscreen({
                                                    base64: AUrlEncodedImageFromBase64(data),
                                                    disableNav: true,
                                                    disableZoom: true,
                                                    allowPrint: true,
                                                    allowFilter: false,
                                                });
                                            },
                                        },
                                    }),
                                    greyOutFields: true
                                });
                                const { $ele } = Alerts.show({
                                    title: ALERT_TITLES.None,
                                    content: tb.build({ classes: ['fw'] }),
                                    buttons: ALERT_BUTTONS.ok
                                });
                                // let scrollTop = $ele.find('table').scrollTop() ?? 0
                                // $ele.find('table').replaceWith(html)
                                // $ele.find('table').scrollTop(scrollTop)
                                initTableAccordions($ele);
                            }).catch(AError.handle);
                        },
                    })
                },
                // 'HasFineImage': {
                //   ...COLUMN_ACTION<{HasFineData: boolean, HasFineImage: boolean} & ADetection>({
                //     iconCls: 'fa-solid fa-print',
                //     btnCls: 'btn-white',
                //     // renderCls(record) {
                //     //   return [
                //     //     ...((record.data.HasFineData) ? ['text-red-i'] : []),
                //     //     ...((record.data.HasFineImage) ? ['text-white-i'] : []),
                //     //   ].join(' ')
                //     // },
                //     // disablePredicate(record) {
                //     //   // @ts-ignore
                //     //   return !record.data.HasFineImage 
                //     // },
                //     onClick({ record }) {
                //       if (!record.data.HasFineImage) {
                //         return
                //       }
                //       AEngine.log('process click!')
                //       Loading.waitForPromises(
                //         requestService.fetch<{FineNumber: any, FineData: any, FineIsPaid: any, FinePaidReference: any, FineImage: any}>({
                //           AssertValues: true,
                //           Query: (/*SQL*/`
                //             SELECT
                //               FineNumber,
                //               FineIsPaid,
                //               FinePaidReference,
                //               FineData,
                //               VerificationVersion,
                //               FineImage
                //             FROM verifications
                //             WHERE
                //               DetectionId=:DetectionId AND
                //               DetectionDeviceId=:DetectionDeviceId AND
                //               VerificationDevice != "ControlCenter1"
                //             ORDER BY VerificationVersion ASC
                //           `),
                //           Params: {
                //             DetectionId: record.data.DetectionId,
                //             DetectionDeviceId: record.data.DetectionDeviceId,
                //           }
                //         })
                //       ).then(async (ares) => {
                //         APhotoHandler.showImageFullscreen({
                //           base64: AUrlEncodedImageFromBase64(ares.Last?.FineImage),
                //           disableNav: true,
                //           disableZoom: true,
                //           allowPrint: true,
                //           allowFilter: false,
                //         })
                //       }).catch(AError.handle)
                //     },
                //   })
                // },
                'Action1': {
                    field: 'Action1',
                    ...COLUMN_ACTION({
                        iconCls: 'fa-solid fa-clock-rotate-left',
                        btnCls: 'btn-white',
                        onClick: ({ record }) => {
                            const { DetectionId, DetectionDeviceId } = record.originalData;
                            if (!DetectionId || !DetectionDeviceId) {
                                return Alerts.noResults();
                            }
                            AEngine.get(ADetectionService).findHistory({
                                DetectionDeviceId,
                                DetectionId
                            });
                        },
                    }),
                },
                'Action2': {
                    field: 'Action2',
                    ...COLUMN_ACTION({
                        iconCls: 'fa-regular fa-map-location',
                        btnCls: 'btn-white',
                        onClick: ({ record }) => {
                            const { DetectionId, DetectionDeviceId, DetectionTime } = record.originalData;
                            detectionService.modalShowOnMap({ DetectionId, DetectionDeviceId, DetectionTime }).catch(AError.handle);
                        }
                    }),
                }
            }, FinesSelectFieldsArr),
            data: AConvertToGridData(response, {
                Action1: () => '',
                Action2: () => '',
                DetectionTime: DATA_DATETIME,
                ResultTime: DATA_DATETIME,
            })
        });
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ParkingRight">ParkingRight</label>
          <div id="ParkingRight" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label" for="IllegallyParked">IllegallyParked</label>
          <div id="IllegallyParked" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>
    
        <div class="form-group">
          <label class="form-label" for="DetectionState">DetectionState</label>
          <div id="DetectionState" class="wrapper-dropdown tree-config dd-disallow-none noselect" maxlength="18">
            <span>All</span>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div template="${ATemplates.WaitingForInput}"></div>
      <div id="table-bryntum" class="hidden"></div>

      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Fines</div>
        </div>
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
