import { AError } from "../classes/AError.js";
import { COLUMN_DATETIME, COLUMN_HIDDEN, COLUMN_TEXT, DATA_DATETIME } from "../classes/AGridTypes.js";
import { ALoopTimer } from "../classes/ALoopTimer.js";
import { sleep } from "../core/AEngine.js";
import { AForm } from "../core/form/AForm.js";
import { AFormInstance } from "../core/form/AFormInstance.js";
import { EVENTS } from "../services/AEventService.js";
import { APrefs } from "../services/APreferenceService.js";
import { AConvertToGridColumns, AConvertToGridData, AShowTable, TransformObjectsToResponse, createArray, isObject, secondsToHHMMSS } from "../utils/tools.js";
export class APage {
    constructor() {
        this.timer = new ALoopTimer(() => {
            const seconds = performance.now() / 1000;
            $('.session-duration').text(secondsToHHMMSS(seconds));
        }, { loopLifeCycle: "PAGE", timeout: 500 }).start();
        this.bgs = [
            'gradient-default',
            ...createArray(36).map((_, i) => `gradient-${i + 1}`)
        ];
        this.changeBackground(preferenceService.load(APrefs.BG_COLOR, this.bgs[0]));
        Events.h_once(EVENTS.DESTRUCT, () => {
            this.changeBackground(preferenceService.load(APrefs.BG_COLOR, this.bgs[0]));
        });
    }
    async init() {
        $('#bg-prev').on('click', _ => this.changeColor(-1));
        $('#bg-next').on('click', _ => this.changeColor(1));
        $('#save').on('click', _ => this.save());
        Events.once("ACI_TABS_CHANGED->profile->tab-session-history", () => {
            Loading.waitForPromises(this.displaySessionHistory()).catch(AError.handle);
        });
        await Loading.waitForPromises([
            this.displayUserInfo(),
            this.displayUserPreferences()
        ]).catch(AError.handle);
        $('#save').prop('disabled', true);
    }
    getNextIndex(index, step) {
        return (index === -1) ? 0 : (index === 0 && step === -1) ? this.bgs.length - 1 : index + step;
    }
    changeColor(step) {
        const index = this.bgs.indexOf(this.selectedBackground);
        const nextIndex = this.getNextIndex(index, step);
        this.changeBackground(this.bgs[nextIndex % this.bgs.length]);
        $('#save').prop('disabled', false);
    }
    changeBackground(newBackground) {
        const $body = $('body');
        if ($body.hasClass(this.selectedBackground)) {
            $body.removeClass(this.selectedBackground);
        }
        $body.addClass(newBackground);
        $('#background').val(newBackground);
        this.selectedBackground = newBackground;
    }
    async displayUserInfo() {
        const { UserDisplayName, User, UserGroups } = stateService.state;
        const $formP = $('#profile-formdata');
        const { UseAuthenticator, UsePassword } = Config;
        const formInputs = [
            { id: 'User', type: 'text', minlength: 0, maxlength: 64, disabled: true },
            { id: 'DisplayName', type: 'text', minlength: 0, maxlength: 64, disabled: true },
            { id: 'UserGroups', type: 'text', minlength: 0, maxlength: 256, disabled: true },
            { id: 'UseAuthenticator', type: 'checkbox', disabled: true, width: 'col-6' },
            { id: 'UsePassword', type: 'checkbox', disabled: true, width: 'col-6' },
        ];
        const form = new AFormInstance({
            ignoreWildcards: false,
            formInputs,
        });
        await form.generate({ translate: true, wrapInColumns: true });
        // const formHtml = await AForm.genForm(formInputs, { translate: true, wrapInColumns: true })
        // $formP.prepend(formHtml)
        // const $form = $formP.find('form')
        await form.injectFormData({
            formData: {
                User: User ?? '?',
                DisplayName: UserDisplayName ?? '?',
                UserGroups: (UserGroups ?? []).join(', '),
                UseAuthenticator,
                UsePassword,
            }
        });
        await form.initFormValidation();
        form.attachTo($formP);
        // await AForm.injectFormData($form, { formData, formInputs })
        // await AForm.initFormValidation($form, formInputs)
    }
    async displayUserPreferences() {
        // const { UserDisplayName, User, UserGroups } = _.getUser()
        // const {UseAuthenticator, UsePassword} = Config
        const $formP = $('#profile-preferences');
        const formPairs = preferenceService.getFormInputsAll();
        formPairs.BG_COLOR.onChange = (formInput, formInputs) => {
            this.changeBackground(formInput.$input.val() ?? this.bgs[0]);
            $('#save').prop('disabled', false);
        };
        formPairs.MENU_OPEN.onChange = (formInput) => {
            menuService.toggleMenu(formInput.$input.prop('checked'));
        };
        const formInputs = Object.values(formPairs).filter(v => v.hidden !== true).map(inp => {
            return Object.assign({ width: 'col-6' }, inp);
        });
        const form = new AFormInstance({
            ignoreWildcards: false,
            formInputs: formInputs,
        });
        const $form = await form.generate({ translate: true, wrapInColumns: true });
        await form.injectFormData({ formData: {}, triggerChange: true });
        await form.initFormValidation();
        form.attachTo($formP);
        $form.on('aci-change', (e) => {
            $('#save').prop('disabled', false);
        });
        // const $formHtml = await AForm.genForm(formInputs, { translate: true, wrapInColumns: true })
        // $formP.prepend($formHtml)
        // const $form = $formP.find('form')
        // await AForm.injectFormData($form, { formInputs })
        // // await AForm.initFormValidation($form, formInputs)
        // formInputs.map(inp => $form.find(`#${inp.id}`)).map($inp => {
        //   $inp.on('change keyup', (e) => {
        //     $('#save').prop('disabled', false)
        //   })
        // })
    }
    async displaySessionHistory() {
        const history = userActionService.getHistory({ limit: 1000, sort: 'DESC' });
        const res = await TransformObjectsToResponse(history);
        this.grid = AShowTable({
            appendTo: 'table-bryntum',
            aci: { resizeToFit: true, flex: 1 },
            selectionMode: { multiSelect: false },
            columns: AConvertToGridColumns(res, {
                timestamp: COLUMN_DATETIME,
                category: { ...COLUMN_TEXT, ...COLUMN_HIDDEN },
                action: COLUMN_TEXT,
                desc: {
                    htmlEncode: false,
                    renderer: ({ value, record }) => {
                        if (record.action === 'NAV') {
                            if (isObject(value) && value.hash) {
                                return value.hash;
                            }
                        }
                        if (isObject(value)) {
                            return Object.keys(value).map(k => {
                                const v = value[k];
                                return `${k}=${JSON.stringify(v)}`;
                            }).join(', ');
                        }
                        return ( /*html*/`${value}`);
                    },
                }
            }),
            data: AConvertToGridData(res, {
                timestamp: DATA_DATETIME
            }),
        });
    }
    async save() {
        const data = AForm.extractFormData($('#profile-preferences > form'), { ignoreWildcards: false, ignoreInvisible: true });
        Object.keys(data).map(k => { return preferenceService.set(k, data[k]); });
        // preferenceService.save(APrefs.BG_COLOR, this.selectedBackground)
        await Loading.waitForPromises([
            sleep(100)
        ]);
        $('#save').prop('disabled', true);
    }
}
export function css() {
    return ( /*html*/`
    <style>
      :root {
        --profile-size: 108px;
      }
      
      .profile-settings {
        position: relative;
        height: 100%;
        margin-left: 8px;
        margin-right: 8px;
      }

      .profile-settings .profile-picture {
        position: relative;
        background: #00b3e3;
        color: #fff;
        font-size: 3rem;
        width: var(--profile-size);
        box-shadow: 0 1px 5px #00000030;
        border-radius: 50%;
        text-align: center;
      }

      .profile-settings .profile-picture,
      .profile-settings .profile-card {
        height: var(--profile-size);
      }

      .profile-settings .card-subsection {
        width: 100%;
      }

      .profile-settings .profile-picture[data-initial]::before {
        color: currentColor;
        content: attr(data-initial);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: 1;
      }

      .profile-settings .profile-card {
        display: flex;
        display: -ms-flexbox;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
      
      .profile-settings .profile-card h2,
      .profile-settings .profile-card h3,
      .profile-settings .profile-card h4 {margin: 0;}

      .profile-settings h2 {}
      .profile-settings h3 {font-size: 0.8rem;}
      .profile-settings h4 {font-size: 0.8rem;font-weight: 400;}

      .profile-settings .group-h {
        display: flex;
        display: -ms-flexbox;
        flex-direction: row;
        align-content: space-between;
        justify-content: space-between;
        flex-wrap: nowrap;
      }
      .profile-settings .aci-tabs.tabs-sticky .aci-tab:first-child {
        /* border-top-left-radius: 7px; */
      }
      .profile-settings .aci-tabs.tabs-sticky .aci-tab:last-child {
        /* border-top-right-radius: 7px; */
      }
      .profile-settings #save {
        margin: calc((59px - 36px) / 2.0) 0;
      }

      .profile-settings .aci-footer {
        background: #f9f9f9;
        border-top: 1px solid #eeeeee;
      }

      .profile-settings .aci-tabs .aci-tab.active {
        background: #fff;
      }
    </style>
  `);
}
export function render() {
    const usr = stateService.state;
    const username = usr.UserDisplayName || usr.User || '?';
    const initials = username.replace(/\s+/g, ' ').split(' ').map((n) => {
        return (n || ' ')[0].toUpperCase();
    }).splice(0, 2).join('');
    return ( /*html*/`
    <div class="profile-settings">
      <div class="columns" style="height: 100%">
        <div class="column col-12" style="height: calc(100% - 60px); overflow-y: auto">

          <div class="columns" id="userdata">
            <div class="column col-6 col-mx-auto">
              <div class="profile-card-header columns my-2">
                <div class="column col-12">
                  <div class="columns">
                    <div class="column col-auto">
                      <div class="profile-picture" data-initial="${initials}"></div>
                    </div>
                    <div class="column text-left">
                      <div class="profile-card">
                        <div class="card-subsection">
                          <h2>${usr.UserDisplayName ?? '?'}</h2>
                          <div class="group-h">
                            <h3 class="${usr.Status}">
                              <i class="fa-solid fa-circle fa-beat" style="--fa-animation-duration: 1s; --fa-beat-scale: 0.8;"></i>
                              ${usr.StatusString ?? '?'}
                            </h3>
                            <h4>Session Duration: <span class="session-duration">00:00:00</span></h4>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column col-6 col-mx-auto">
              <div class="aci-tabs tabs-sticky tabs-flex tabs-xl top-zero" tabgroup="profile">
                <button class="aci-tab active" tab="tab-user-info" style="flex: 0 1 50%;">
                  <!-- <i class="fa-solid fa-calendar-day"></i> -->
                  <span>User Info</span>
                </button>
                <button class="aci-tab" tab="tab-preferences" style="flex: 0 1 50%;">
                  <!-- <i class="fa-solid fa-clock-rotate-left"></i> -->
                  <span>Preferences</span>
                </button>
                <button class="aci-tab" tab="tab-session-history" style="flex: 0 1 50%;">
                  <!-- <i class="fa-solid fa-clock-rotate-left"></i> -->
                  <span>Session History</span>
                </button>
              </div>
            </div>
          </div>

          <div class="columns fh" style="position: relative; height: calc(100% - ${Math.round(138 + 50 + 15)}px);">
            <div tabgroup="profile" tabview="tab-user-info" class="column col-6 col-mx-auto">
              <div id="profile-formdata" class=""></div>
            </div>
            <div tabgroup="profile" tabview="tab-preferences" class="column col-6 col-mx-auto">
              <div id="profile-preferences" class=""></div>
            </div>
            <div tabgroup="profile" tabview="tab-session-history" class="column col-6 col-mx-auto">
              <div id="table-bryntum" class="bryntum-no-header fh"></div>
            </div>
          </div>
        </div>
        
        <div class="column col-12">
          <div class="columns aci-footer">
            <div class="column col-6 col-mx-auto text-right" style="height: 59px;">
              <button id="save" class="btn btn-primary col-3" disabled="disabled">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `);
}
