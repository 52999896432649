import { AError } from "../../classes/AError.js";
import { ASortCards } from "../../classes/ASortCards.js";
import { AEngine } from "../../core/AEngine.js";
import { AStatisticsService } from "../../services/AStatisticsService.js";
import { AConvertToGridColumns, AConvertToGridData, ARound, AShowTable, hoursToHHMMSS } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.translationKeys = [
            'Driver',
            'ScanHours',
            'Scans',
            'Suspects'
        ];
        this.translationKeysTable = [
            'Average Scans Per Hour',
            'Average Suspects Per Hour'
        ];
    }
    async init() {
        FilterManager.load();
        this.tTitle = await Translate.get('Average Per Hour');
        this.translations = await Translate.get(this.translationKeys);
        this.tableColumns = await Translate.get(this.translationKeysTable).then(t => {
            return this.translationKeysTable.map(key => t[key]);
        });
        const $export = $('#export');
        Events.on(`ACI_TABS_CHANGED->views`, ({ tabview, $tabview }) => {
            $export.toggleClass('hidden', !(tabview === 'tab-table'));
        });
        Loading.waitForPromises(this.refresh()).catch(AError.handle);
        $('#RefreshButton').on('click', _ => Loading.waitForPromises(this.refresh()).catch(AError.handle));
    }
    async fetchDriverHours(filters, options) {
        const { usernameMap } = options;
        const res = await Loading.waitForPromises(requestService.query({
            Query: (`
          SELECT
            IFNULL(ss.User, '') as User,
            SUM(TravelDuration) / 3600 AS OperationInHours,
            SUM(IF((EnforcingLeft > 0 or EnforcingRight > 0 ), TravelDuration, 0)) / 3600 AS ScanInHours,
            SUM(TravelDistance) / 1000 AS DistanceInKM
          FROM waysegment_entries ways
          LEFT JOIN (select sessionId, Max(User) User from sessionstatus group by sessionId) ss ON (ways.SessionId = ss.SessionId)
          WHERE :ToDate > FromDateTime AND ToDateTime > :FromDate
          GROUP BY IFNULL(ss.User, '')
        `),
            Params: filters
        }));
        const output = {};
        res.Rows.map(([user, operationHours, scanHours]) => {
            if (usernameMap.hasOwnProperty(user)) {
                user = usernameMap[user];
            }
            output[user] = {
                user,
                operationHours,
                scanHours
            };
        });
        return output;
    }
    async fetchAllUsers() {
        /**
         * SELECT ss.User, ways.* FROM waysegment_entries ways
         * INNER JOIN sessionstatus ss ON (ways.SessionId = ss.SessionId)
         * WHERE '2021-12-12' > FromDateTime AND '2017-01-01' < ToDateTime
         */
        const map = {};
        const users = await requestService.query(`SELECT User, DisplayName FROM users`);
        users.Rows.map(([user, displayName]) => {
            map[user] = displayName || user;
            if (displayName && displayName.length > 0) {
                map[displayName] = displayName;
            }
        });
        return map;
    }
    async calcUserPerformance(filters) {
        const usernameMap = await Loading.waitForPromises(this.fetchAllUsers());
        const map = await Loading.waitForPromises(AEngine.get(AStatisticsService).fetchGroupedByDetectionUser(filters, { usernameMap }));
        const hours = await this.fetchDriverHours(filters, { usernameMap });
        this.data = {};
        await Promise.all(Object.keys(map).map(async (key) => {
            if (!hours.hasOwnProperty(key)) {
                return;
            }
            const { operationHours, scanHours } = hours[key];
            const user = key || await Translate.get('Unknown');
            return this.data[user] = {
                user: user,
                statistics: map[key],
                operationHours,
                scanHours
            };
        }));
        const response = await this.fetchScanHours();
        const driverData = response.Rows.map(([driver, calcHours, totalPH, suspectsPH, followUpsPH, sanctionsPH]) => {
            return { driver, calcHours, totalPH, suspectsPH, followUpsPH, sanctionsPH };
        });
        this.sortCards?.clearCards();
        this.drawDrivers(driverData);
        this.createDriverTable(response);
    }
    async fetchScanHours() {
        const response = {
            Columns: this.translationKeys,
            ColumnsTranslated: this.translationKeys.map(k => {
                return this.translations[k];
            }),
            Rows: Object.keys(this.data).map(key => {
                const userData = this.data[key];
                const Total = userData.statistics.getTotal();
                const Suspects = userData.statistics.getSuspects();
                const Sanctions = userData.statistics.getSanctions();
                const Followups = userData.statistics.getSuccessfulFollowUps();
                const calcHours = userData.scanHours;
                return [
                    key,
                    hoursToHHMMSS(calcHours),
                    ARound(Total / calcHours, 1),
                    ARound(Suspects / calcHours, 1),
                    ARound(Followups / calcHours, 1),
                    ARound(Sanctions / calcHours, 1)
                ];
            })
        };
        return response;
    }
    createDriverTable(response) {
        response.ColumnsTranslated = response.ColumnsTranslated.slice(0, 2).concat(this.tableColumns);
        this.grid = AShowTable({
            aci: {
                resizeToFit: true,
                flex: (!this.grid || response.Rows.length === 0) ? 1 : undefined,
            },
            appendTo: 'table-bryntum',
            columns: AConvertToGridColumns(response),
            data: AConvertToGridData(response),
        });
    }
    drawDrivers(array) {
        this.sortCards = new ASortCards({
            $parent: $('.visual-parent'),
            $chips: $('.chips-select'),
            fadeIn: true
        });
        array.map(data => {
            const [_, tCalcHours, tScans, tSuspects] = this.translationKeys;
            const initials = data.driver.split(' ').map(name => {
                try {
                    return (name || '')[0].toUpperCase();
                }
                catch (err) {
                    return '';
                }
            }).splice(0, 2).join('');
            this.sortCards.append(/*html*/ `
        <div class="column col-3" data-name="${data.driver}" data-scans="${data.totalPH}" data-followups="${data.suspectsPH}">
          <div class="columns card-backoffice white-bg" style="padding: 10px;">

            <div class="chip" style="zoom: 1.2; margin: 0 auto 5px auto;">
              <figure class="avatar avatar-sm" data-initial="${initials}" style="background-color: #5755d9;"></figure>
              ${data.driver}
            </div>

            <table class="styled-table grid-like fw table-text-right">
              <tr>
                <td colspan="2">
                  <label class="legend-label">${this.translations[tCalcHours]}</label>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  ${data.calcHours}
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <label class="legend-label">${this.tTitle}</label>
                </td>
              </tr>
              <tr>
                <td>${this.translations[tScans]}</td>
                <td>${data.totalPH}</td>
              </tr>
              <tr>
                <td>${this.translations[tSuspects]}</td>
                <td>${data.suspectsPH}</td>
              </tr>
            </table>

          </div>
        </div>
      `);
        });
    }
    async refresh() {
        const filters = FilterManager.saveExplicit();
        await this.calcUserPerformance(filters);
        // await this.fetchVerificationUserPerformance(filters)
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div class="flex-content">
        <div class="aci-tabs tabs-sticky tabs-fixed-md top-zero tabs-fw" tabgroup="views">
          <button class="aci-tab active" tab="tab-visual">
            <!-- <i class="fa-solid fa-calendar-day"></i> -->
            <span>Visual</span>
          </button>
          <button class="aci-tab" tab="tab-table">
            <!-- <i class="fa-solid fa-clock-rotate-left"></i> -->
            <span>Table</span>
          </button>
        </div>
        <div class="columns col-gapless" style="height: calc(100% - 60px);">
          <div class="column col-12">
            <div tabgroup="views" tabview="tab-visual" class="h-padding">

              <div class="column col-12 chips-select">
                <span sortkey="data-name" sortDirection="ASC" class="chip active">
                  Sort by Name
                </span>
                <span sortkey="data-scans" sortDirection="DESC" sortType="Number" class="chip">
                  Sort by ScansPerHour
                </span>
                <span sortkey="data-followups" sortDirection="DESC" sortType="Number" class="chip">
                  Sort by SuspectsPerHour
                </span>
              </div>
              <div class="visual-parent columns card-list" style="justify-content: left;"></div>
            </div>
            <div tabgroup="views" tabview="tab-table" class="h-padding fh">
              <div id="table-bryntum" class="fh"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="columns footer aci">
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12 hidden" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}
