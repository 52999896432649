import { AError } from "../../classes/AError.js";
import { AForm } from "../../core/form/AForm.js";
import { COLUMN_ACTION, COLUMN_DATETIME, COLUMN_HIDDEN, COLUMN_MULTI_SELECT, COLUMN_TEXT, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { AEngine } from "../../core/AEngine.js";
import { AKpiBlock } from "../../kpi/AKpiBlock.js";
import { AKpiSystem } from "../../kpi/AKpiSystem.js";
import { ALERT_TITLES, ALERT_BUTTONS, ALERT_STATUS } from "../../services/AAlertService.js";
import { ADragService } from "../../services/ADragService.js";
import { EVENTS } from "../../services/AEventService.js";
import { AConvertToGridColumns, AConvertToGridData, appendResponseRow, appendResponseRows, AShowTable, TransformObjectsToResponse } from "../../utils/tools.js";
import { AFormInstance } from "../../core/form/AFormInstance.js";
import { AKpiOrm } from "../../orm/AKpiOrm.js";
export class APage {
    get CurrentView() {
        return this.grid?.selectedRecord?.data?.View || 'Default';
    }
    get $filters() {
        return $('.form-filters');
    }
    constructor() {
        this.kpiOrm = new AKpiOrm();
        this.prevKpiView = '';
        this.kpiSystem = new AKpiSystem({
            allowEdit: true,
            showSubtitle: true,
            getView: () => this.CurrentView,
            overrideSubtitle: () => this.getRangeKey(),
            filtersFallback: (kpi) => this.getFilters(),
            onWidgetCreated: (widget) => {
                Loading.waitForPromises(this.refresh({ selected: this.grid.selectedRecord.data }));
            },
            onWidgetDeleted: (widget) => {
                Loading.waitForPromises(this.refresh({
                    selected: this.grid.selectedRecord.data,
                    forceClearContainer: true
                }));
            },
            isOperationsPage: false,
            $container: $('.kpis .configurable-kpis'),
            $showIfEmpty: $('#show-if-empty-widgets'),
            $btnCreate: $('#create-btn'),
            widgets: [],
        });
    }
    async init() {
        FilterManager.setActive(false, { $filters: this.$filters });
        FilterManager.load(this.$filters);
        await permissionService.refetchUsergroups();
        const $u = $('#Usergroups');
        $u.on('dropdown.change', (e) => {
            const keys = $u.ddSelectedKeysWithoutPrefix();
            // const usergroups = filterService.dropdowns['Usergroups'].selectedKeysWithoutPrefix
            console.log('filters: ', $u.ddSelectedKeysWithoutPrefix());
            this.grid.selectedRecord?.set('Usergroups', keys);
            Loading.waitForPromises(widgetService.createOrUpdateView({
                View: this.kpiSystem.View,
                Usergroups: keys
            })).catch(AError.handle);
        });
        this.initDraggable();
        $('#create-view').on('click', _ => this.displayCreateViewModal());
        $('#cancel-view').on('click', _ => this.setState({ tab: 'tab-kpi-list' }));
        $('#Preview').on('change', _ => {
            if (this.grid.selectedRecord) {
                Loading.waitForPromises(this.refresh({ selected: this.grid.selectedRecord.data }));
            }
        });
        await this.refreshList();
        await this.refreshPermissionsTab();
    }
    async refreshPermissionsTab() {
        const resDefaultViews = await this.kpiOrm.fetchDefaultViews();
        const res = await TransformObjectsToResponse(Usergroups.map(ug => {
            return {
                UserGroup: ug.UserGroup,
                UserGroupText: ug.UserGroupText,
                DefaultView: resDefaultViews.find(v => v.UserGroup === ug.UserGroup)?.DefaultView
            };
        }));
        appendResponseRow(res, 'ActionEdit');
        this.gridDefaultView = AShowTable({
            appendTo: 'table-bryntum-kpi-default',
            columns: AConvertToGridColumns(res, {
                UserGroup: {
                    htmlEncode: false,
                    renderer: (args) => {
                        return args.record.UserGroupText ?? args.value;
                    }
                },
                UserGroupText: COLUMN_HIDDEN,
                ActionEdit: COLUMN_ACTION({
                    iconCls: 'fa-solid fa-pencil text-primary',
                    btnCls: 'btn-white',
                    onClick: ({ record }) => {
                        Loading.waitForPromises(this.showEditDefaultViewModal(record.originalData)).catch(AError.handle);
                    },
                }),
            }),
            data: AConvertToGridData(res),
        });
        // const $form = await Loading.waitForPromises(form.generate({ wrapInColumns: true }))
        // $('#kpi-permissions').html('')
        // $('#kpi-permissions').append($form)
        // await form.injectFormData()
        // await form.initFormValidation()
    }
    async showEditDefaultViewModal(data) {
        const resDefaultViews = await this.kpiOrm.fetchDefaultViews();
        // const resAvailableViews = await kpiOrm.fetchViewsForUserGroup({ UserGroup: data.UserGroup })
        const availableViews = this.allViews.filter(v => !v.Usergroups || v.Usergroups.includes(data.UserGroup));
        const form = new AFormInstance({
            ignoreWildcards: true,
            formInputs: [
                {
                    id: 'UserGroup',
                    type: 'select',
                    options: Usergroups.map(v => ({ id: v.UserGroup, text: v.UserGroupText })),
                    value: data.UserGroup,
                    disabled: true
                },
                {
                    id: 'DefaultView',
                    type: 'select',
                    options: availableViews.map(v => ({ id: v.View, text: v.View })),
                    value: resDefaultViews.find(v => v.UserGroup === data.UserGroup)?.DefaultView || 'Default',
                    // options: this.allViews.map(v => ({ id: v.View, text: v.View })),
                    // value: data.View || this.allViews.First?.View
                }
            ]
        });
        const events = Alerts.show({
            title: ALERT_TITLES.None,
            content: await form.generate({ wrapInColumns: true, translate: true })
        });
        // await form.injectFormData({
        //   formData: {
        //     UserGroup: resDefaultViews.find(v => v.UserGroup === data.UserGroup)?.UserGroup,
        //     View: resDefaultViews.find(v => v.UserGroup === data.UserGroup)?.View || 'Default',
        //     // View: resAvailableViews.find(v => v.View === data.View)?.View || 'Default',
        //     IsDefault: undefined
        //   }
        // })
        await form.initFormValidation();
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                const formData = form.extractFormData({ cleanData: true });
                AEngine.log('formData', formData);
                await Loading.waitForPromises(this.kpiOrm.setDefaultView(formData));
                await this.refreshPermissionsTab();
                return true;
            }
            catch (err) {
                AError.handle(err);
                return false;
            }
        });
    }
    setState(opt) {
        const value = opt.tab;
        const $tab = $(`[tab="${value}"]`);
        if ($tab.length === 0) {
            AError.handle(`Couldn't find tab [tab="${value}"]`);
        }
        $tab.trigger('click');
        if (value === 'tab-kpi-list') {
            filterService.setActive(true);
        }
        else {
            filterService.setActive(false, { silent: true });
        }
    }
    initDraggable() {
        AEngine.get(ADragService).createDraggable();
    }
    getUsergroupOptionsMulti(record) {
        return Usergroups.map(g => {
            return {
                id: g.UserGroup,
                text: g.UserGroupText,
                checked: (record) ? ((record.Usergroups || []).includes(g.UserGroup) || record.Usergroups === null) : true
            };
        });
    }
    async genFormInputs(record) {
        const formInputs = [
            {
                id: 'View',
                minlength: 3,
                maxlength: 256,
                type: 'text',
                disabled: record !== undefined,
                overrideHasError: ($inp, hasError) => {
                    const View = ($inp.val() || '').toLowerCase();
                    const viewAlreadyExists = this.allViews?.find(v => v.View.toLocaleLowerCase() === View) !== undefined;
                    return hasError || (View === 'default' || View === 'operations') || viewAlreadyExists;
                }
            },
            {
                id: 'Usergroups',
                type: 'multiselect',
                disallowNone: true,
                options: this.getUsergroupOptionsMulti(record)
            },
        ];
        return formInputs;
    }
    async displayCreateViewModal(record) {
        // this.setState({ tab: 'tab-kpi-form' })
        // const $tabview = $(`[tabview="tab-kpi-form"]`).eq(0)
        const formInputs = await this.genFormInputs(record);
        const formData = Object.assign({
            ModificationUser: stateService.User,
            ModificationDevice: _.getSession().DeviceName,
            ModificationTime: new Date()
        }, record);
        const events = Alerts.show({
            title: ALERT_TITLES.Info,
            buttons: ALERT_BUTTONS.saveCancel,
            content: await Loading.waitForPromises(AForm.genForm(formInputs, { translate: true }))
        });
        const $form = events.$ele.find('form');
        await AForm.injectFormData($form, { formInputs, formData });
        await AForm.initFormValidation($form, formInputs);
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                if ($form.find('.is-error').length > 0) {
                    Alerts.show({
                        title: ALERT_TITLES.Warning,
                        content: await Loading.waitForPromises(Translate.get(`View Name is already in use!`)),
                        info: 'FORM_INCOMPLETE'
                    });
                    return false;
                }
                const options = AForm.extractFormData($form, { ignoreWildcards: true });
                if (options !== undefined) {
                    // if (await kpiService.customExists(options)) { }
                    if (this.allViews.find(v => v.View === options.View)) {
                        Alerts.show({
                            title: ALERT_TITLES.Warning,
                            content: await Loading.waitForPromises(Translate.get(`View Name is already in use!`)),
                            info: 'FORM_INCOMPLETE'
                        });
                        return false;
                    }
                    await Loading.waitForPromises(widgetService.createOrUpdateView(options));
                    await Loading.waitForPromises(this.refreshList());
                    // this.setState({ tab: 'tab-kpi-list' })
                }
            }
            catch (err) {
                AError.handle(err);
            }
        });
        return { $form };
    }
    async refreshList() {
        try {
            this.allViews = await widgetService.fetchAllViews();
            const originalRes = this.allViews.Original;
            Events.tryInvoke(EVENTS.CONTENT_RESIZE);
            appendResponseRows(originalRes, ['ActionDelete']);
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                aci: {
                    resizeToFit: true,
                    resizeToFitReverse: true,
                    flex: 1,
                    skipResizeColumns: ['Usergroups']
                },
                selectionMode: {
                    multiSelect: false,
                    row: true,
                },
                columns: AConvertToGridColumns(originalRes, {
                    'View': COLUMN_TEXT,
                    'Widgets': COLUMN_HIDDEN,
                    'Usergroups': COLUMN_MULTI_SELECT,
                    'ModificationUser': COLUMN_HIDDEN,
                    'ModificationDevice': COLUMN_HIDDEN,
                    'ModificationTime': { ...COLUMN_DATETIME, ...COLUMN_HIDDEN },
                    'CreatedAt': { ...COLUMN_DATETIME, ...COLUMN_HIDDEN },
                    'ActionDelete': COLUMN_ACTION({
                        iconCls: 'fa-regular fa-trash text-red',
                        btnCls: 'btn-white',
                        disablePredicate: (record) => ['Operations', 'Default'].includes(record.data.View)
                    }),
                }),
                data: AConvertToGridData(originalRes, {
                    'ModificationTime': DATA_DATETIME,
                    'CreatedAt': DATA_DATETIME,
                }),
            });
            this.grid.on('selectionchange', (ev) => {
                if (ev.mode === 'row') {
                    FilterManager.setActive(!(ev.action === 'deselect' && ev.selection?.length === 0), { $filters: this.$filters });
                    if (ev.action === 'deselect') {
                        this.kpiSystem.$showIfEmpty.toggleClass('hidden', true);
                        this.kpiSystem.clearContainer();
                    }
                    if (ev.selected && ev.selected.length) {
                        Loading.waitForPromises(this.refresh({ selected: ev.selected[0].data }));
                    }
                }
            });
            this.grid.on('cellclick', (e) => {
                const { record, column } = e;
                console.log(record.originalData);
                if (record.id && record.id.indexOf('group-header') !== -1 || $(e.target).find('button.disabled').length > 0) {
                    return;
                }
                switch (column.data.field) {
                    case 'ActionDelete':
                        this.displayDeleteViewModal(record);
                        break;
                }
            });
            this.grid.selectedRecord = this.grid.store.last;
        }
        catch (err) {
            AError.handle(err);
        }
    }
    async displayDeleteViewModal(record) {
        const alert = Alerts.show({
            translatedTitle: await Loading.waitForPromises(Translate.get('Delete View')),
            buttons: ALERT_BUTTONS.yesNo,
            content: await Loading.waitForPromises(Translate.get(/*html*/ `
        Are you sure you want to delete this View?
      `))
        });
        alert.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                record.remove();
                await Loading.waitForPromises([
                    widgetService.deleteView({ View: record.data.View })
                ]);
            }
            catch (err) {
                AError.handle(err);
            }
            finally {
                Loading.waitForPromises(this.refreshList());
            }
        });
    }
    getRangeKey() {
        return $('#Preview').val();
    }
    getFilters() {
        const rangeKey = this.getRangeKey();
        const rangeOption = AKpiBlock.defaultDateRangeOptions[rangeKey];
        const filters = rangeOption.getFilters();
        return {
            index: rangeOption.index,
            FromDate: filters.FromDate,
            ToDate: filters.ToDate,
        };
    }
    async refresh(opt) {
        try {
            const { selected, forceClearContainer } = opt;
            const filters = {
                View: selected.View ?? 'Default',
                ...this.getFilters()
            };
            const $usergroups = $('#Usergroups');
            $usergroups.dd()?.restoreState(selected.Usergroups, { useKeysWithoutPrefix: true });
            $usergroups.toggleClass('disabled', filters.View === 'Default');
            const viewChanged = this.prevKpiView.length === 0 || this.prevKpiView !== filters.View;
            this.prevKpiView = filters.View;
            const widgets = await widgetService.fetchActiveWidgets(filters);
            await this.kpiSystem.loadAll({
                widgets: widgets,
                clearContainer: forceClearContainer || viewChanged
            });
            await Loading.waitForPromises(this.kpiSystem.invokeRefreshAll({ force: true, userInput: false, enableLoadingIndicators: true }, filters));
            Events.tryInvoke(EVENTS.CONTENT_RESIZE);
        }
        catch (err) {
            AError.handle(err);
        }
    }
}
export function css() {
    return ( /*html*/`
    <style>
      .highcharts-container .aci-hover-item td {
        border: 4px solid transparent;
      }
      *[tabgroup="charts"][tabview] {
        height: 100%;
      }
      .views {
        height: calc(100% - 55px);
      }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div id="Rapport" class="flex-child fh">
      <div class="flex-content">
        <div class="splitter two-split fh" style="overflow-y: auto; overflow-x: hidden;">

          <div class="drag-section part-one" style="width: 25%">
            <div class="bryntum-container has-footer-2">

              <div class="aci-tabs tabs-flex tabs-sticky tabs-fixed-md tabs-xl v-zero sharp-border bg-main" tabgroup="views">
                <button style="flex: 0 1 50%;" class="aci-tab active" tab="tab-kpi-list">
                  <i class="fa-solid fa-list"></i>
                  <span>Views</span>
                </button>
                <button style="flex: 0 1 50%;" class="aci-tab" tab="tab-kpi-form">
                  <i class="fa-solid fa-list-check"></i>
                  <span>Default</span>
                </button>
              </div>

              <div class="views" style="position: relative">
                <div class="fh" style="overflow-y: auto">
                  <div class="columns col-gapless fh">
                    <div class="column col-12">
                      <div tabgroup="views" tabview="tab-kpi-list" class="fh">
                        <div id="table-bryntum" class="fh"></div>
                      </div>
                      <div tabgroup="views" tabview="tab-kpi-form" class="fh" style="overflow-y: auto;">
                        <div id="table-bryntum-kpi-default" class="fh"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div tabgroup="views" tabview="tab-kpi-list">
                  <div class="columns footer aci">
                    <div class="column col-12">
                      <button id="create-view" class="btn btn-primary col-12">Create View</button>
                    </div>
                  </div>
                </div>
                <div tabgroup="views" tabview="tab-kpi-form" style="overflow-y: auto;">
                  <div class="columns footer aci" style="height: 59px">
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div id="separator1" class="drag-seperator separator"></div>

          <div class="drag-section part-two" style="width: 75%">
            <div class="bryntum-container has-footer-2 bg-main">
              <div class="kpis fh">
              
                <div class="panel-p ph -custom-scroll fh" style="overflow-y: auto">
                  <div class="columns configurable-kpis" style="padding-bottom: 15px;"></div>
                  <div id="show-if-empty-widgets" class="columns" style="height: 100%; margin-top: 15px;">
                    <div class="column col-3 col-mx-auto text-center " style="align-self: center">
                      <i class="fa-regular fa-search fa-7x fa-fw mb-3"></i>
                      <p class="empty-title h5 mb-1">This view has no widgets!</p>
                    </div>
                  </div>
                </div>
                
                <div class="menu-kpi sidebar-popover">
                  <ul>
                    <li class="kpi-label kpi-label-actions noselect">
                      <a href="#" class="noselect">Actions</a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa-solid fa-expand"></i>
                        Fullscreen
                      </a>
                    </li>
                    <li class="kpi-label kpi-label-filters noselect">
                      <a href="#" class="noselect">Filters</a>
                    </li>
                    <li class="kpi-label kpi-label-settings noselect">
                      <a href="#" class="noselect">Settings</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="columns col-oneline footer aci form-filters form-horizontal">
                <div class="column col-auto">
                  ${AForm.genDateSelectInput({
        id: 'Preview',
        label: 'Preview Range',
        dateRangeOpt: AKpiBlock.defaultDateRangeOptions,
        cls: 'col-6'
    })}
                </div>
                <label class="form-label column col-auto" for="Usergroups">Usergroups</label>
                <div late-init="true" id="Usergroups" name="Usergroups" class="wrapper-dropdown dd-disallow-none copycat noselect column col-3 col-mr-auto">
                  <span>None</span>
                  <ul class="dropdown c-scroll"></ul>
                </div>
                <div class="column col-auto col-ml-auto">
                  <button id="create-btn" style="min-width: 200px" class="btn btn-primary">
                    Add Widget
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  `);
}
