import { AEngine } from "../core/AEngine.js";
import { Theme_DarkTheme } from "../themes/dark-theme.js";
import { Theme_LightTheme } from "../themes/light-theme.js";
import { isDarkMode } from "../utils/tools.js";
import { APreferenceService, APrefs } from "./APreferenceService.js";
export class AThemeService {
    constructor() {
        this.themes = {
            'light': Theme_LightTheme,
            'dark': Theme_DarkTheme,
        };
        this.charts = [];
    }
    async autoInit() {
        // this.themes.light.apply()
        $('.header-strip [action="TOGGLE_DARKMODE"]').on('click', _ => this.toggleDarkMode());
        window.matchMedia('(prefers-color-scheme: dark)').addListener((e) => {
            AEngine.log(`changed to ${e.matches ? "dark" : "light"} mode`);
            this.setTheme(e.matches ? 'dark' : 'light');
        });
        const themeKey = AEngine.get(APreferenceService).get(APrefs.THEME) || (isDarkMode() ? 'dark' : 'light');
        this.setTheme(themeKey);
    }
    toggleDarkMode() {
        const prefService = AEngine.get(APreferenceService);
        const nextTheme = ((prefService.get(APrefs.THEME) ?? 'light') === 'light') ? 'dark' : 'light';
        this.setTheme(nextTheme);
        prefService.set(APrefs.THEME, nextTheme);
    }
    setTheme(themeKey) {
        const theme = this.themes[themeKey];
        // Set Html Attribute
        $('html').attr('dark', (themeKey === 'dark') ? '' : null);
        // Toggle header element
        const $darklight = $('.header-strip [action="TOGGLE_DARKMODE"] i');
        $darklight.eq(0).toggleClass('hidden', themeKey === 'dark');
        $darklight.eq(1).toggleClass('hidden', themeKey === 'light');
        // Update highcharts instances
        $('.highcharts-container').toggleClass('chart-dark-theme', themeKey === 'dark');
        // Run theme-specific code
        return theme.apply();
    }
    watchChart(chart) {
        // Remove unmounted charts
        this.charts = this.charts.filter(c => {
            return (c && c?.container?.isConnected === true);
        });
        // Add chart to watchlist
        if (chart !== undefined) {
            this.charts.push(chart);
        }
        return chart;
    }
}
AThemeService.CurrentTheme = 'light';
