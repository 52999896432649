import { AEngine } from "./AEngine.js";
export class AGpsDevice {
    get Latitude() { return this.data.Latitude ?? 0; }
    get Longitude() { return this.data.Longitude ?? 0; }
    constructor(options) {
        this.options = options;
        this.paused = true;
        this.data = {
            Direction: 0,
            GpsTime: new Date().toJSON(),
            Latitude: 0,
            Longitude: 0,
            Precision: 1000,
            Speed: 0,
        };
        this.startLoop();
    }
    startLoop() {
        if (navigator?.geolocation) {
            this.paused = false;
            navigator.geolocation.watchPosition((position) => {
                this.data.Precision = position.coords.accuracy;
                this.data.Direction = position.coords.heading ?? 0;
                this.data.Speed = position.coords.speed ?? 0;
                this.data.Latitude = position.coords.latitude;
                this.data.Longitude = position.coords.longitude;
                if (position.timestamp) {
                    let d = new Date(position.timestamp);
                    this.data.GpsTime = d.toJSON();
                }
                if (this.paused === false && this.sendGpsStateStream != undefined) {
                    this.sendGpsStateStream();
                }
            }, undefined, Object.assign({
                enableHighAccuracy: true,
                maximumAge: 0,
                timeout: 1000
            }, this.options ?? {}));
        }
    }
    setData(data) {
        Object.keys(data).map(key => {
            this.data[key] = data[key];
        });
    }
    pause() {
        this.paused = true;
        AEngine.log('AGpsDevice %cpaused');
    }
    resume() {
        this.paused = false;
        AEngine.log('AGpsDevice %cresumed');
    }
    sendGpsStateStream() {
        let GpsStateStream = this.data;
        CCCClient.SendMessage("GpsStateStream", 1, GpsStateStream, 10);
    }
}
