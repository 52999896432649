import { AError } from "../classes/AError.js";
import { EVENTS } from "../services/AEventService.js";
import { AFormatDate } from "../utils/tools.js";
import { AChartTooltipFormatter, ResizeChart } from "./charts.js";
import { _getEle } from "../utils/maps.js";
export var ASessionChartSerieEnum;
(function (ASessionChartSerieEnum) {
    ASessionChartSerieEnum[ASessionChartSerieEnum["Detecting"] = 0] = "Detecting";
    ASessionChartSerieEnum[ASessionChartSerieEnum["Scans"] = 1] = "Scans";
    ASessionChartSerieEnum[ASessionChartSerieEnum["Verifications"] = 2] = "Verifications";
    ASessionChartSerieEnum[ASessionChartSerieEnum["Speed"] = 3] = "Speed";
})(ASessionChartSerieEnum || (ASessionChartSerieEnum = {}));
async function getNodeChartOptions(opt) {
    const translations = await Translate.get([
        'Detecting',
        'Verifications',
        'Scans',
        'Speed',
    ]);
    const base = [
        {
            key: ASessionChartSerieEnum[ASessionChartSerieEnum.Detecting],
            enum: ASessionChartSerieEnum.Detecting,
            type: 'areaspline',
            title: translations["Detecting"],
            min: 0,
            max: 1.0,
            showlabel: false,
            fill: false,
            color: "rgba(  57, 151,  26, .5 )",
            opacity: 1.,
            lineWidth: 8.,
            // hidden: true
        },
        {
            key: ASessionChartSerieEnum[ASessionChartSerieEnum.Scans],
            enum: ASessionChartSerieEnum.Scans,
            type: 'column',
            title: translations["Scans"],
            color: "rgba(   0, 175, 227, .5 )",
            fill: true,
            opacity: 1.,
            lineWidth: 0.,
            opposite: false
        },
        {
            key: ASessionChartSerieEnum[ASessionChartSerieEnum.Verifications],
            enum: ASessionChartSerieEnum.Verifications,
            type: 'column',
            title: translations["Verifications"],
            color: "rgba(   175, 175, 227 , .5 )",
            min: 0,
            fill: true,
            opacity: 1.,
            lineWidth: 0.,
            opposite: false,
            hidden: true
        },
        {
            key: ASessionChartSerieEnum[ASessionChartSerieEnum.Speed],
            enum: ASessionChartSerieEnum.Speed,
            type: 'areaspline',
            title: translations["Speed"],
            min: 0.,
            max: 140.,
            format: '{value} km/h',
            color: "rgba( 227,   0,  57, .5 );",
            fill: false,
            opacity: 1.,
            lineWidth: .8,
            opposite: true
        }
    ];
    return base.filter((s) => {
        return ADeviceChartSnapshot.allowSeries(opt, s.enum);
    }).sort((a, b) => a.enum - b.enum);
}
export class ADeviceChartSnapshot {
    get chartId() { return `${this.session.NodeName}-Chart`; }
    constructor(session) {
        this.session = session;
    }
    isMounted() {
        return this.chart !== undefined && Object.keys(this.chart).length > 0 && this.chart.container.isConnected === true;
    }
    resize() {
        if (this.isMounted()) {
            const h = $(this.chart.renderTo || this.chart.container).height();
            if (h !== undefined) {
                // ResizeChart(this.chart!, { height: h })
                ResizeChart(this.chart, { height: h }, true, { silentErrors: true });
            }
        }
    }
    update(chartData) {
        this.chart.series.map(s => {
            // Using string getter because it's a user defined property
            const data = chartData[s.userOptions['enum']];
            s.setData(data, true, true);
        });
    }
    destroy() {
        this.chart?.destroy();
        delete this.chart;
    }
    async makeTimeChartInternal(eleOrId, axisOptions, opt) {
        const renderElement = _getEle(eleOrId);
        if (renderElement == null) {
            throw new Error(`Couldn't find element with id "${eleOrId}"`);
        }
        let CurrentTime = (new Date()).getTime();
        let YAxes = [];
        let Series = [];
        for (let i = 0; i < axisOptions.length; i++) {
            let Axe = {
                title: {
                    text: null,
                    style: { color: new Highcharts.Color(axisOptions[i].color) }
                },
                visible: axisOptions[i].showlabel,
                labels: { style: { color: new Highcharts.Color(axisOptions[i].color) } }
            };
            if (axisOptions[i].min !== undefined)
                Axe.floor = axisOptions[i].min;
            if (axisOptions[i].max !== undefined)
                Axe.ceiling = axisOptions[i].max;
            if (axisOptions[i].showlabel !== undefined)
                Axe.visible = axisOptions[i].showlabel;
            if (axisOptions[i].opposite !== undefined)
                Axe.opposite = axisOptions[i].opposite;
            if (axisOptions[i].format !== undefined)
                Axe.labels.format = axisOptions[i].format;
            let Serie = {
                key: axisOptions[i].key,
                enum: axisOptions[i].enum,
                type: axisOptions[i].type,
                name: axisOptions[i].title,
                data: [],
                fillOpacity: axisOptions[i].fill ? axisOptions[i].opacity : 0.,
                lineWidth: axisOptions[i].lineWidth,
                yAxis: i,
                color: new Highcharts.Color(axisOptions[i].color).setOpacity(axisOptions[i].opacity).get()
            };
            if (axisOptions[i].min !== undefined)
                Serie.floor = axisOptions[i].min;
            if (axisOptions[i].max !== undefined)
                Serie.ceiling = axisOptions[i].max;
            YAxes.push(Axe);
            Series.push(Serie);
        }
        const chartInstance = themeService.watchChart(new Highcharts.Chart({
            chart: {
                // styledMode: true,
                renderTo: renderElement,
                // animation: Highcharts.svg, // don't animate in old IE
                marginRight: 80,
                height: opt?.height ?? undefined,
                width: opt?.width ?? undefined,
                zooming: opt?.enableZooming ? { type: 'x' } : undefined,
                events: {
                    selection(event) {
                        if (event.resetSelection === true) {
                            this.xAxis[0].userOptions.zoomEnabled = false;
                        }
                        else {
                            this.xAxis[0].userOptions.zoomEnabled = true;
                        }
                        return undefined;
                    },
                }
            },
            title: { text: "" },
            xAxis: {
                type: 'datetime',
                max: CurrentTime,
                min: CurrentTime - 60 * 60 * 1000
            },
            yAxis: YAxes,
            legend: {
                enabled: true
            },
            exporting: {
                enabled: false
            },
            plotOptions: {
                areaspline: {
                    marker: {
                        enabled: false
                    }
                },
                area: {
                    stacking: 'normal',
                    lineColor: '#666666',
                    lineWidth: 1,
                    marker: {
                        lineWidth: 1,
                        lineColor: '#666666'
                    }
                }
            },
            series: Series,
            tooltip: {
                formatter: opt?.tooltip?.formatter ?? await AChartTooltipFormatter({ addTotal: false, addPercentage: false, hideZeroValues: true, unit: '' }),
                shared: true,
                useHTML: true,
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                borderWidth: 0,
                padding: 0,
                // valueSuffix: ` ${}`,
            },
        }));
        if (opt?.autoReflow === true) {
            Events.on(EVENTS.CONTENT_RESIZE, () => {
                const h = $(chartInstance.renderTo || chartInstance.container).height();
                if (h !== undefined) {
                    ResizeChart(chartInstance, { height: h });
                }
            });
        }
        return chartInstance;
    }
    async makeChart() {
        const chartOptions = await getNodeChartOptions(this.session);
        $(`#${this.chartId}`).css('height', '100%');
        this.chart = await this.makeTimeChartInternal(this.chartId, chartOptions, {
            enableZooming: true,
            autoReflow: false,
            tooltip: {
                shared: true,
                formatter: await AChartTooltipFormatter({
                    mapToLabel: (context) => {
                        return AFormatDate(new Date(context.x));
                    },
                    addTotal: false,
                    addPercentage: false,
                    hideZeroValues: true,
                    unit: ''
                })
            }
        });
        Events.tryInvoke(EVENTS.CONTENT_RESIZE);
    }
    setExtremes() {
        // Using string getter because it's a user defined property
        const userOpt = this.chart?.xAxis[0]?.userOptions ?? undefined;
        if (userOpt !== undefined && userOpt['zoomed'] !== true) {
            this.chart?.xAxis[0].setExtremes(Date.now() - 60 * 60 * 1000, Date.now(), true, false); // node.Chart.axes[0].setExtremes(Date.now() - 60 * 60 * 1000, Date.now(), true, false)
        }
    }
    static allowSeries({ NodeType }, Serie) {
        switch (Serie) {
            case ASessionChartSerieEnum.Scans:
            case ASessionChartSerieEnum.Speed:
                return NodeType !== 'CentralVerification';
            case ASessionChartSerieEnum.Detecting:
                return this.isScanDevice({ NodeType });
            case ASessionChartSerieEnum.Verifications:
                return this.isVerificationDevice({ NodeType });
            default:
                AError.handleSilent(`Unexpected ASessionChartSerieEnum Value: ${Serie}`);
                return false;
        }
    }
    static isVerificationDevice({ NodeType }) {
        const allowedArray = ['Pda', 'CentralVerification'];
        return allowedArray.includes(NodeType);
    }
    static isScanDevice({ NodeType }) {
        const allowedArray = ['ScanAuto', 'ScanScooter', 'ScanSegway', 'ScanBike'];
        return allowedArray.includes(NodeType);
    }
    static chartAllowed({ NodeType }) {
        // const allowedArray: ANodeType[] = ['Pda','ScanAuto','ScanScooter','ScanSegway','ScanBike','CentralVerification']
        // return allowedArray.includes(NodeType)
        return this.isVerificationDevice({ NodeType }) || this.isScanDevice({ NodeType });
    }
}
