import { AError } from "../classes/AError.js";
import { AImageCropper } from "../classes/AImageCropper.js";
import { AUserManualBasicWindow } from "../classes/usermanual/AUserManualBasicWindow.js";
import { AFormInstance } from "../core/form/AFormInstance.js";
import { AUploadedImageOrm } from "../orm/AUploadedImageOrm.js";
import { AUserManualOrm } from "../orm/AUserManualOrm.js";
import { toast } from "../utils/toasts.js";
import { AUrlEncodedImageFromBase64 } from "../utils/tools.js";
import { ALERTS, ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "./AAlertService.js";
import { EVENTS } from "./AEventService.js";
export class AUserManualService {
    constructor() {
        this.cachedImages = {};
        this.orm = new AUserManualOrm();
        this.imageOrm = new AUploadedImageOrm();
        // if (!AEngine.isDevelopmentMode) {
        //   AEngine.log(`AUserManualService Using Fake ORM`)
        // }
        // this.orm = AEngine.isDevelopmentMode ? new AUserManualOrm() : new AFakeUserManualOrm()
        // this.imageOrm = AEngine.isDevelopmentMode ? new AUploadedImageOrm() : new AFakeUploadedImageOrm()
    }
    get entries() {
        return this.cache.toArray();
    }
    get groupedEntries() {
        const groups = {};
        for (let it of this.entries) {
            if (!groups.hasOwnProperty(it.MarkdownId)) {
                groups[it.MarkdownId] = [];
            }
            groups[it.MarkdownId].push(it);
        }
        return groups;
    }
    async autoInit() {
        Events.h_once(EVENTS.PREFETCH, async () => {
            await this.reloadData();
        });
    }
    async prefetchImages(markdown) {
        var regex = /\{[ ]*IMG:[ ]?(.{36})[ ]?}/g;
        var result;
        let ImageIds = [];
        while ((result = regex.exec(markdown)) !== null) {
            ImageIds.push(result[1]);
        }
        await this.fetchImages(ImageIds);
    }
    async fetchImages(imgIds) {
        const ImageIds = imgIds.filter(v => !this.cachedImages.hasOwnProperty(v));
        const images = await this.imageOrm.fetch({ ImageIds: ImageIds });
        images.map(img => {
            this.cachedImages[img.ImageId] = img;
        });
    }
    async fetchAllImages() {
        return this.imageOrm.fetchAll();
    }
    async renderMarkdown(markdown) {
        var regex = /\{[ ]*IMG:[ ]?(.{36})[ ]?}/g;
        var result;
        while ((result = regex.exec(markdown)) !== null) {
            if (result !== null && result[1] != null) {
                const { LargeImage, AltText } = this.cachedImages[result[1]];
                markdown = markdown.replace(result[0], `![${AltText}](${AUrlEncodedImageFromBase64(LargeImage)})`);
            }
        }
        return await Promise.resolve().then(() => marked.parse(markdown));
    }
    async create(options) {
        const success = await this.orm.create(options);
        if (success) {
            await this.reloadData();
        }
        return success;
    }
    async unlink(options) {
        await this.orm.unlink(options);
        await this.reloadData();
    }
    async link(id_mr, markdownId) {
        await this.orm.link(id_mr, markdownId);
        await this.reloadData();
    }
    async reloadData() {
        this.cache = await this.orm.fetchAll();
    }
    async showUserManual() {
        const currentPageIdMr = routeService.meta.menuItem?.id_mr;
        const manuals = this.entries.filter(e => e.Id_Mr == currentPageIdMr);
        const manual = manuals.find(e => e.Lang == Language) ?? manuals[0];
        if (manual !== undefined) {
            const win = new AUserManualBasicWindow({ showTitle: false, manual });
            await win.show();
        }
    }
    async showLinkPopup(opt) {
        // const currentPageIdMr = opt?.Id_Mr || routeService.meta.menuItem?.id_mr
        // const manual = this.entries.find(e => e.Id_Mr == opt.Id_Mr) //?? this.entries.find(e => e.Id_Mr == currentPageIdMr)
        const form = new AFormInstance({
            ignoreWildcards: true,
            formInputs: [
                {
                    id: 'MarkdownId',
                    label: 'Markdown',
                    type: 'select',
                    options: this.entries.map(item => {
                        return {
                            id: item.MarkdownId,
                            text: item.Title,
                        };
                    })
                },
                {
                    id: 'Id_Mr',
                    label: 'Page',
                    type: 'select',
                    skipTranslate: true,
                    options: Object.keys(menuService.menuFlat).filter(k => menuService.menuFlat[k].id_mr != null).map(k => {
                        let { id_mr, url, title } = menuService.menuFlat[k];
                        if (this.entries.findIndex(v => v.Id_Mr == id_mr) !== -1) {
                            return null;
                        }
                        return {
                            id: id_mr,
                            text: `${url} (${id_mr})`
                        };
                    }).filter(v => v != null)
                }
            ]
        });
        const events = Alerts.show({
            translatedTitle: await Translate.get('No manual assigned!'),
            content: await Loading.waitForPromises(form.generate({ translate: true })),
            buttons: ALERT_BUTTONS.saveCancel,
        });
        await form.injectFormData({
            formData: { Id_Mr: opt.Id_Mr, MarkdownId: opt.MarkdownId }
        });
        await form.initFormValidation();
        return new Promise((resolve) => {
            events.on(ALERT_STATUS.ON_MODAL_CLOSED, async ({ action }) => {
                if (action !== ALERT_STATUS.ON_ACTION_PROCEED) {
                    return resolve(action);
                }
                try {
                    if (!form.validate()) {
                        Alerts.incomplete();
                        return false;
                    }
                    const data = form.extractFormData({ cleanData: true });
                    await this.link(data.Id_Mr, data.MarkdownId);
                    resolve(action);
                }
                catch (err) {
                    AError.handle(err);
                }
                finally {
                    // Loading.waitForPromises(this.refresh())
                }
            });
        });
    }
    showUploadForm(opt) {
        const events = Alerts.show({
            title: ALERT_TITLES.None,
            type: ALERTS.Medium,
            content: ( /*HTML*/`
        <input id="input-upload-img" type="file" class="hidden">
        <div class="upl-step-1">
          <button id="image-upload-btn" class="btn btn-primary">
            <i class="fa-regular fa-cloud-arrow-up"></i>
            <span>Upload Image</span>
          </button>
        </div>
        
        <div class="upl-step-2">
          <div class="form-group">
            <label class="form-label" for="AltText">Alt Text</label>
            <input class="form-input" type="text" id="AltText" name="AltText" disabled="disabled">
          </div>
        </div>

        <div class="upl-step-3 hidden">
          <p id="b64"></p>
          <div class="image-preview"><img src="#"></div>
          <div class="image-preview"><img src="#"></div>
          <div class="image-preview"><img src="#"></div>
        </div>
      `),
            buttons: ALERT_BUTTONS.createCancel
        });
        const { $ele } = events;
        const $altText = $ele.find('#AltText');
        const $uploadProxy = $ele.find('#image-upload-btn');
        const $upload = $ele.find('#input-upload-img');
        const $imgArr = $ele.find('.image-preview img').toArray().map(ele => $(ele));
        $uploadProxy.on('click', () => $upload.trigger('click'));
        let images;
        $upload.on('change', async (e) => {
            // Read File
            const files = $upload.prop('files');
            if (files.length == 0) {
                images = undefined;
                $altText.prop('disabled', 'disabled');
                $('.upl-step-1').removeClass('hidden');
                $('.upl-step-3').addClass('hidden');
                return toast({ msg: 'No File Selected!' });
            }
            if (files.length > 1) {
                toast({ msg: 'Multiple file upload is currently not supported!' });
            }
            const loadedFile = files.item(0);
            const imgCropper = new AImageCropper({ file: loadedFile });
            images = await imgCropper.cropToScaled({ renderTo: $imgArr });
            console.log(images);
            $altText.val(loadedFile.name.replace(/\..*/g, ''));
            $altText.removeProp('disabled');
            $altText.removeAttr('disabled');
            $('.upl-step-1').addClass('hidden');
            $('.upl-step-3').removeClass('hidden');
            // const fr = new FileReader()
        });
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                if (!images)
                    return false;
                let name = $altText.val() || null;
                const success = await Loading.waitForPromises(this.imageOrm.create({
                    AltText: name || 'Image 1',
                    LargeImage: images.large.toBase64({ stripPrefix: true }),
                    MediumImage: images.medium.toBase64({ stripPrefix: true }),
                    SmallImage: images.small.toBase64({ stripPrefix: true }),
                    FileSize: images.large.fileSize,
                    MimeType: images.large.mimeType,
                }));
                opt.onUploaded(success);
            }
            catch (err) {
                AError.handle(err);
            }
        });
    }
}
