import { ResizeChart } from "../charts/charts.js";
import { ADetectionStatistics } from "../classes/ADetectionStatistics.js";
import { AVerification } from "../classes/AUnificationTypes.js";
import { AEngine, sleep } from "../core/AEngine.js";
import { EVENTS } from "../services/AEventService.js";
import { AStatisticsService } from "../services/AStatisticsService.js";
import { AInputDate, ARound, autoReflowChart, createArray } from "../utils/tools.js";
import { AKpiBlock, AKpiState } from "./AKpiBlock.js";
const TOOLTIP_SETTINGS = {
    shared: true,
    useHTML: true,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderWidth: 0,
    padding: 0,
    formatter: function () {
        let context = this;
        let parts = [];
        for (let point of context.points) {
            parts.push((`
        <tr>
          <td><span class="chart-dot" style="background-color: ${point.series.color}"></span> ${point.series.name}: </td>
          <td><b>${point.y}</b></td>
        </tr>
    `).trim());
        }
        return (`
      <table class="tooltip-chart-table">
        <thead>
          <tr>
          <th colspan="2">${context.points[0].x.indexOf(':') === -1 ? AInputDate(new Date(context.points[0].x)) : context.points[0].x}</th>
          </tr>
        </thead>
        <tbody>
          ${parts.join('')}
        </tbody>
      </table>
    `);
    }
};
export class AKpiBlockSanctions extends AKpiBlock {
    constructor(opt) {
        super({
            refreshImplemented: true,
            optionsImplemented: true,
            ...opt
        });
        this.displayLegend = (opt?.Settings ?? {})['displayLegend'] === 'no' ? false : true;
        this.charts = createArray(this.getFilterOptionKeys().length, () => undefined);
        this.verificationRef = new AVerification();
        this.createResizeListener();
    }
    set chart(value) {
        const index = this.$filter.find('option:selected').index();
        this.charts[index] = value;
    }
    get chart() {
        const index = this.$filter.find('option:selected').index();
        return this.charts[index];
    }
    async init() {
        super.init();
        this.$filter.on('change', (e) => {
            const filterVal = this.$filter.val();
            // const visibleChartId = `${this.idFilter}-${this.$filter.val()! as string}`
            const $chartWrapperArr = this.$kpiView.find('[filter]').toArray().map(e => $(e));
            $chartWrapperArr.map($chart => {
                $chart.toggleClass('hidden', $chart.attr('filter') !== filterVal);
                console.log($chart.attr('filter') + '!==' + filterVal);
            });
        });
    }
    createResizeListener() {
        Events.on(EVENTS.CONTENT_RESIZE, async () => {
            await sleep(1);
            this.resize();
        });
        this.resize();
    }
    resize() {
        ResizeChart(this.chart, this.getSize(), false);
        this.chart?.reflow();
    }
    async handleSanctionsDaily({ FromDate, ToDate }, sanctionsDailyRes) {
        let hasSanctions = false;
        const TIME_SPAN = ARound(Math.abs(moment.duration(moment(FromDate).diff(moment(ToDate))).asDays()), 0);
        const output = {};
        const categories = [];
        for (let i = 0; i <= TIME_SPAN; i++) {
            const date = moment(ToDate).subtract(TIME_SPAN - i, 'days').format('YYYY-MM-DD');
            categories.push(date);
            output[date] = {};
        }
        sanctionsDailyRes.toTuple().map(([obj, stats]) => {
            const DetectionDate = obj['DetectionDate'];
            if (!output.hasOwnProperty(DetectionDate)) {
                output[DetectionDate] = {};
            }
            const verification = stats.Verification;
            Object.keys(verification.Options.Fined.Options).map(key => {
                const v = verification.Options.Fined.Options[key];
                if (!output[DetectionDate].hasOwnProperty(v.Key)) {
                    output[DetectionDate][v.Key] = v.Count;
                }
                else {
                    output[DetectionDate][v.Key] += v.Count;
                }
            });
            // output[DetectionDate][verification] = stats.
        });
        // sanctionsDailyRes.map(row => {
        //   if (!output.hasOwnProperty(row.DetectionDate)) {
        //     output[row.DetectionDate] = {}
        //   }
        //   output[row.DetectionDate][row.Verification] = row.Total
        // })
        const { labelIndexes, labelTranslations } = await this.getLabelIndexes();
        const detectionStatistics = new ADetectionStatistics();
        const vOptions = detectionStatistics.Verification.Options.Fined.Options;
        const series = [];
        Object.keys(output).map(DetectionDate => {
            for (let vKey of Object.keys(vOptions)) {
                const vOption = vOptions[vKey];
                const sIndex = labelIndexes.indexOf(vKey);
                if (sIndex === -1) {
                    throw new Error(`Unifications Error, couldn't find key "${vKey}"`);
                }
                if (!series.hasOwnProperty(sIndex)) {
                    series[sIndex] = { name: labelTranslations[vKey], data: [] };
                }
                const unificationKey = vOption.Key;
                let count = (output[DetectionDate].hasOwnProperty(unificationKey)) ? Number(output[DetectionDate][unificationKey]) : 0;
                if (count > 0) {
                    hasSanctions = true;
                }
                series[sIndex].data.push(count);
            }
        });
        for (let serie of series) {
            if (serie.data.length <= TIME_SPAN) {
                const amountToAdd = TIME_SPAN - serie.data.length;
                for (let i = 0; i < amountToAdd; i++) {
                    serie.data.push(0);
                }
            }
        }
        return {
            hasSanctions,
            categories,
            series
        };
    }
    createSanctionsChart(id, opt) {
        const { categories, series } = opt;
        if (Events.logLevel > 0) {
            AEngine.log(`AKpiBlockSanctions.createSanctionsChart(id, opt)`, { id, opt });
        }
        const finesChart = themeService.watchChart(autoReflowChart(Highcharts.chart(id, {
            chart: { type: 'column' },
            title: { text: '' },
            xAxis: {
                categories: categories,
                crosshair: true
            },
            yAxis: { min: 0, title: { text: '' } },
            tooltip: TOOLTIP_SETTINGS,
            plotOptions: { column: { pointPadding: 0.2, borderWidth: 0, stacking: 'normal' } },
            exporting: { enabled: false },
            series,
            legend: {
                enabled: this.displayLegend
            },
        })));
        ResizeChart();
        finesChart.reflow();
        return finesChart;
    }
    async handleSanctionsHourly(timelineRes) {
        let hasSanctions = false;
        const output = {};
        timelineRes.toTuple().map(([obj, stats]) => {
            const detectionHour = parseInt(obj['DetectionHour'].split(' ').pop());
            output[detectionHour] = stats;
        });
        const vOptions = new AVerification().Options.Fined.Options;
        const { labelIndexes, labelTranslations } = await this.getLabelIndexes();
        let series = [], low = 8, high = new Date().getHours();
        Object.keys(output).map(strHour => {
            const hour = parseInt(strHour);
            low = Math.min(low, hour);
            high = Math.max(high, hour);
        });
        // Initialize series
        series = Object.keys(vOptions).map(vKey => {
            return { name: labelTranslations[vKey], data: [] };
        });
        // Fill wih zero's
        series.map(s => { for (let i = low; i <= high; i++) {
            s.data.push(0);
        } });
        Object.keys(output).map(strHour => {
            Object.keys(vOptions).map(vKey => {
                const sIndex = labelIndexes.indexOf(vKey);
                if (sIndex === -1) {
                    throw new Error(`Couldn't find labelIndex for vKey="${vKey}"`);
                }
                const hour = parseInt(strHour);
                const dataIndex = hour - low;
                const dataToPush = output[hour].Verification.Options.Fined.Options[vKey].Count;
                if (dataToPush > 0) {
                    hasSanctions = true;
                }
                series[sIndex].data[dataIndex] = dataToPush;
            });
        });
        const categories = [];
        for (let i = low; i <= high; i++) {
            const cat = (i.toString()).padStart(2, '0') + ':00';
            categories.push(cat);
        }
        return {
            hasSanctions,
            categories,
            series
        };
    }
    updateChart(chart, { categories, series }) {
        chart.xAxis.map(axis => {
            axis.setCategories(categories);
        });
        chart.series.map((serie, i) => {
            // @ts-ignore // TODO: FIX HIGHCHARTS
            serie.setData(series[i].data);
        });
        chart.redraw();
    }
    async getLabelIndexes() {
        const finedOpts = new AVerification().Options.Fined.Options;
        const labelIndexes = Object.keys(finedOpts);
        const labelTranslations = await Translate.get(labelIndexes);
        return {
            labelIndexes,
            labelTranslations
        };
    }
    getFilterOptions() {
        return {
            'Today': { index: 0, getFilters: () => FilterManager.betweenTodayAndTomorrow },
            'Last 30 Days': { index: 1, getFilters: () => FilterManager.betweenLastMonthAndNowDay },
        };
    }
    async fetchChartData(filters) {
        const { FirstIndex, LastIndex } = this.verificationRef.Options.Fined;
        const hardFilters = {
            FirstIndex,
            LastIndex,
            DetachedFromPda: detectionStateRef.Options.InProgress.Options.DetachedFromPda.FirstIndex,
            DetachedFromCentralVerification: detectionStateRef.Options.InProgress.Options.DetachedFromCentralVerification.FirstIndex,
        };
        if (filters.index === 0) {
            const sanctionsHourlyRes = await AEngine.get(AStatisticsService).fetchDynamic({
                ...hardFilters,
                ...filters,
            }, {
                baseTable: `detections_final`,
                columns: [{
                        name: 'DetectionHour',
                        select: `DATE_FORMAT(DetectionTime, '%Y-%m-%d %H')`,
                        groupBy: true
                    }],
                where: [
                    `df.Verification BETWEEN :FirstIndex AND :LastIndex`
                ]
            });
            return await this.handleSanctionsHourly(sanctionsHourlyRes);
        }
        else {
            const sanctionsDailyRes = await AEngine.get(AStatisticsService).fetchDynamic({
                ...hardFilters,
                ...filters,
            }, {
                baseTable: `detections_final`,
                columns: [{
                        name: 'DetectionDate',
                        select: `DATE_FORMAT(DetectionTime, '%Y-%m-%d')`,
                        groupBy: true
                    }],
                where: [
                    `df.Verification BETWEEN :FirstIndex AND :LastIndex`,
                ]
            });
            return await this.handleSanctionsDaily(filters, sanctionsDailyRes);
        }
    }
    async refresh(filters = this.filterOption) {
        const days = moment(filters.ToDate).diff(filters.FromDate, 'day');
        // if (filters.index === undefined) { filters.index = days > 1 ? 1 : 0 }
        // This kpi block only has 2 filterOptions, filter.index is not the same as the chartIndex
        let chartIndex = days > 1 ? 1 : 0;
        const chartData = await this.fetchChartData(filters);
        if (chartData.hasSanctions === false || chartData.categories.length <= 1) {
            return AKpiState.noResults;
        }
        if (this.chart == null) {
            this.chart = await this.createSanctionsChart(`chart-fines-${this.idBlock}-${chartIndex}`, chartData);
            ResizeChart(this.chart, this.getSize(), false);
        }
        else {
            this.updateChart(this.chart, chartData);
        }
    }
    async render() {
        const filterOptionKeys = this.getFilterOptionKeys();
        return await super.renderView({
            title: 'Sanctions',
            viewHtml: ( /*html*/`
        ${filterOptionKeys.map((opt, i) => {
                return ( /*html*/`
            <div filter="${i}" class="kpi-chart">
              <div id="chart-fines-${this.idBlock}-${i}"></div>
            </div>
            `);
            }).join('')}
      `)
        });
    }
}
