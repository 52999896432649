export class AChartService {
    constructor() {
        this.fields = {
            ScanDevice: {
                table: "detections_final",
                select: "GetDeviceName(detections_final.DetectionDeviceId)",
                group_by: "detections_final.DetectionDeviceId",
                translate: false,
                process: (value) => value
            },
            DetectionState: {
                table: "detections_final",
                select: "detections_final.DetectionState",
                group_by: "detections_final.DetectionState",
                translate: true,
                process: (value) => value + "_test"
            },
            ParkingRight: {
                table: "detections_final",
                select: "detections_final.ParkingRight",
                group_by: "detections_final.ParkingRight",
                translate: true,
                process: (value) => value
            },
            ParkingRightRoot: {
                table: "detections_final",
                select: "detections_final.ParkingRight",
                group_by: "detections_final.ParkingRight",
                translate: true,
                process: (value) => value /// Todo Find the root of the ParkingRight Unification
            },
            Area: {
                table: "area",
                select: "area.Name",
                group_by: "area.Name",
                translate: false,
                process: (value) => value
            }
        };
        this.tablesJoins = {
            detections_final: "",
            detections: /* sql */ ` left join detections on(detections_final.DetectionId=detections.DetectionId and detections_final.DetectionDeviceId=detections.DetectionDeviceId)`,
            detections_geo: /* sql */ ` left join detections_geo on(detections_final.DetectionId=detections_geo.DetectionId and detections_final.DetectionDeviceId=detections_geo.DetectionDeviceId)`,
            parkingrigts: /* sql */ ` left join parkingrigts on(detections_final.DetectionId=parkingrigts.DetectionId and detections_final.DetectionDeviceId=parkingrigts.DetectionDeviceId and detections_final.ParkingRightVersion=parkingrigts.ParkingRightVersion )`,
            verifications: /* sql */ ` left join verifications on(detections_final.DetectionId=verifications.DetectionId and detections_final.DetectionDeviceId=verifications.DetectionDeviceId and detections_final.VerificationVersion=verifications.VerificationVersion )`,
            area: /* sql */ ` left join detection_geo_object_best arealink on (detections_final.DetectionId=arealink.DetectionId and detections_final.DetectionDeviceId=arealink.DetectionDeviceId and arealink.GeoType='Area') left join geo_objects_history as area on(arealink.GeoType=area.GeoType and arealink.GeoId = area.GeoId and arealink.GeoVersion = area.GeoVersion )`
        };
    }
    getFields() {
        return Object.keys(this.fields);
    }
    async fetch(category, bars, where, filters) {
        where.push(/* sql */ `${this.fields[category].select} IS NOT NULL`);
        where.push(/* sql */ `detections_final.DetectionId BETWEEN (((UNIX_TIMESTAMP(:FromDate)-946684800)<<16)*1000) AND (((UNIX_TIMESTAMP(:ToDate)-946684800)<<16)*1000)`);
        let keys = [category].concat(bars);
        let fields = keys.map(key => this.fields[key]);
        let uniqueTables = fields.map(field => field.table).filter((value, index, self) => self.indexOf(value) === index);
        let select = keys.map(key => `${this.fields[key].select} as ${key}`);
        let joins = uniqueTables.map(table => this.tablesJoins[table]);
        let group = fields.map(field => field.group_by).filter((value, index, self) => self.indexOf(value) === index);
        let order = keys.map(key => `${this.fields[key].select}`);
        let query = `SELECT ${select.join(' ,')}, COUNT(*) as Total
                    FROM detections_final ${joins.join(' ')} 
                    WHERE ${where.join(' AND ')} 
                    GROUP BY ${group.join(', ')} 
                    ORDER BY ${order.join(', ')};`;
        console.log(query);
        let result = await requestService.query({
            Query: query,
            Params: filters
        });
        result.Rows.map(row => {
            row[0] = this.fields[category].process(row[0]);
            for (let bar = 0; bar < bars.length; bar++) {
                row[1 + bar] = this.fields[bars[bar]].process(row[1 + bar]);
            }
        });
        return result;
    }
    makeBarChart(elementId, title, repsonse, showTotals) {
        let bars = repsonse.ColumnsTranslated.slice(1, -1);
        let categories = [];
        let bar_groups = {};
        let barCount = repsonse.Columns.length - 2;
        for (let row of repsonse.Rows) {
            let count = +row.at(-1);
            let category = row[0];
            categories.push(category);
            for (let b = 0; b < bars.length; b++) {
                let bar_stack = bars[b];
                let bar_name = row[b + 1];
                if (bar_name !== null) {
                    if (bar_groups[bar_stack] === undefined)
                        bar_groups[bar_stack] = {};
                    if (bar_groups[bar_stack][bar_name] === undefined)
                        bar_groups[bar_stack][bar_name] = {};
                    bar_groups[bar_stack][bar_name][category] = bar_groups[bar_stack][bar_name][category] === undefined ? count : bar_groups[bar_stack][bar_name][category] + count;
                }
            }
        }
        categories = categories.filter((value, index, self) => self.indexOf(value) === index);
        let series = [];
        for (let bar_stack in bar_groups) {
            for (let bar_name in bar_groups[bar_stack]) {
                let data = [];
                for (let categorie of categories) {
                    data.push(bar_groups[bar_stack][bar_name][categorie] || 0);
                }
                series.push({
                    name: bar_name,
                    data: data,
                    stack: bar_stack
                });
            }
        }
        return themeService.watchChart(Highcharts.chart({
            chart: {
                renderTo: elementId,
                type: 'column'
            },
            title: {
                text: title,
                align: 'left'
            },
            xAxis: {
                categories: categories
            },
            yAxis: {
                allowDecimals: false,
                min: 0,
                title: {
                    text: 'Scans'
                }
            },
            tooltip: {
                format: '<b>{key}</b><br/>{series.name}: {y}<br/>' +
                    'Total: {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },
            series: series
        }));
    }
    example_ScanDevice_X_Area() {
        return {
            Columns: ["ScanDevice", "Area", "Total"],
            ColumnsTranslated: ["ScanDevice", "Area", "Total"],
            Rows: [
                ["ScanAuto1", null, 1],
                ["ScanAuto1", "Area1", 1],
                ["ScanAuto1", "Area1", 1],
                ["ScanAuto1", "Area2", 1],
                ["ScanAuto2", "Area2", 1]
            ]
        };
    }
    example_ScanDevice_X_Area_Zone() {
        return {
            Columns: ["ScanDevice", "Area", "Zone", "Total"],
            ColumnsTranslated: ["ScanDevice", "Area", "Zone", "Total"],
            Rows: [
                ["ScanAuto1", null, "Zone1", 1],
                ["ScanAuto1", "Area1", null, 1],
                ["ScanAuto1", "Area1", "Zone2", 1],
                ["ScanAuto1", "Area2", "Zone1", 1],
                ["ScanAuto2", "Area2", "Zone2", 1]
            ]
        };
    }
}
