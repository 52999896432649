export class ATheme {
    constructor(options) {
        this.options = options;
    }
    apply() {
        // Set Highcharts options
        Highcharts.setOptions(this.options.Highcharts);
        // Set Bryntum Grid stylesheet
        bryntum.grid.DomHelper.setTheme(this.options.Grid.styleName);
    }
}
